
const login = {
  SIGN_IN: '登录',
  SIGN_IN_NOW: '登录界面',
  WELCOME_TO_THE_SYSTEM: '欢迎来到学校管理系统',
  FORGOT_PASSWORD: '忘记密码',
  USERNAME: '用户名',
  PASSWORD: '密码',
  CONFIRM_PASSWORD: '确认您的密码',
  TWO_PASSWORDS_NOT_MATCH: '已输入密码不匹配',
  INCORRECT_USERNAME_PASSWORD: '用户名/密码错误',
  EMAIL: '邮箱',
  CONFIRM_EMAIL: '确认您的邮箱',
  TWO_EMAILS_NOT_MATCH: '已输入邮箱不匹配',
  INVALID_EMAIL: '此邮箱地址无效',
  SEND_VERIFICATION_EMAIL: '发送验证邮件',
  VERIFICATION_EMAIL_SENT: '验证邮件已发送',
  RESET_PASSWORD: '重置密码',
  RESET_YOUR_PASSWORD: '重置您的密码',
  RESET_PASSWORD_FAILED: '重置密码失败',
  VERIFY_URL_LINK: '验证URL链接',
  SIGN_OUT: '登出',
  SIGN_OUT_CHECK: '您确定现在登出吗',
  VALIDATING_USER_CREDENTIAL: '验证身份信息中',
  BYE: '再会'
}

const menu = {
  DASHBOARD: '仪表板',
  SCHOOL_DASHBOARD: '学校仪表板',
  STUDENT: '学生',
  SCHOOL_ANALYTICS: '校内分析',
  SCHOOL_EXECUTIVES: '校内高管',
  ACADEMIC_EXTERNAL: '学术分析',
  ATTENDANCE: '出勤率分析',
  BEHAVIOR: '学生行为分析',
  LEARNING: '学习',
  WELLBEING: '福利相关',
  SYSTEM_MANAGEMENT: '系统管理',
  GENERAL: '通用',
  USER: '用户',
  MAINTENANCE: '维护',
  PARENT_MODULE: '家长端模块',
  PLATFORM_MANAGEMENT: '平台管理',
  DATA: '数据',
  SCHOOLS: '学校',
}

const header = {
  HI: '您好',
  UPDATED_IN: '更新于',
  SETTING: '设置',
  VIEW_NOTIFICATION: '检查消息',
  NEED_HELP: '寻求帮助',
}

const subjects = {
  ENGLISH: '英语',
  MATH: '数学',
  RELIGIOUS_EDUCATION: '宗教教育',
  HEALTH_PHYSICAL_EDUCATION: '体育',
  SCIENCE: '科学',
  LANGUAGES: '语言',
  RESEARCH_INVESTIGATION: '研究与发现',
  HUMANITIES: '人文学',
  ART: '美术',
  LEARNING_ENHANCEMENT_TEAM: '学习辅导团队',
  FOOD: '烹饪',
  PATHWAYS: '道路',
  TECHNOLOGIES: '科技'
}

const filter = {
  SEARCH_STUDENT_ID_NAME: '根据ID/姓名搜索学生',
  SEARCH_BY_FILTER: '过滤器搜索',
  SEARCH: '搜索',
  RESULT: '结果',
  YEAR: '年份',
  NOT_SELECTED: '未选择',
  SEMESTER: '学期',
  HOMEROOM_CODE: '班号',
  DOMAIN: '学科',
  SUBJECT: '课程',
  SUBJECTS: '课程',
  SUBJECT_CLASS: '课程班号',
  ALL: '全部',
  APPLY: '应用',
  UPDATE_STUDENT_LIST: '更新学生列表',
  WHATS_NEW: '新消息',
  SEARCH_STUDENT: '搜索学生',
  SCHOOL_LINKS: "校内链接",
  DAILY_MESSAGE: '每日消息',
  TEACHER_ABSENCE: '教师缺席时间',
  STUDENT_LIST: '学生列表',
  CLASS: '班级'
}

const userProfile = {
  MY_PROFILE: '我的账号信息',
  USER_PROFILE: '用户档案',
  RESET_NOW: '重置密码',
  RESET_PASSWORD_SUCCESS: '密码重置成功',
  PREVIOUS_PASSWORD: '旧密码',
  NEW_PASSWORD: '新密码',
  CONFIRM_NEW_PASSWORD: '确认新密码',
}

const dashboard = {
  SCHOOL_PROFILE: '校内基础信息',
  TOTAL_NUM_STUDENTS: '学生总数',
  TOTAL_NUM_TEACHERS: '教师总数',
  TOTAL_NUM_STAFFS: '职工总数',
  TOTAL_NUM_STUDENTS_12: '12年级学生总数',
  TOTAL_NUM_STUDENTS_11: '11年级学生总数',
  TOTAL_NUM_STUDENTS_10: '10年级学生总数',
  TOTAL_NUM_STUDENTS_9: '9年级学生总数',
  TOTAL_NUM_STUDENTS_8: '8年级学生总数',
  TOTAL_NUM_STUDENTS_7: '7年级学生总数',
  SCHOOL_YEARLY_PERFORMANCE: '校内年度表现',
  SCHOOL_NAPLAN_OVERVIEW_BY_YEAR_YEAR_LEVEL: '校内NAPLAN表现概览（根据年份和年级划分）',
  IN_YEAR: '按年份',
  FOR_YEAR_LEVEL: '按年级',
  TEACHER_STAFF_MSG: '职工信息',
  IS_MARKED: '已批改',
  SUBMISSION_DATE: '提交日期',
  STUDENT_AMOUNT: '已批改学生数量',
  TASK_DUE_DATE_LIST: '作业截止日期列表',
  TODAY_CALENDAR: '日历',
  NO_EVENT_TODAY: '无今日活动',
  VIEW_CALENDAR: '查看日历',
  EVENT_TITLE: '活动标题',
  START_TIME: '开始时间',
  END_TIME: '结束时间',
  LOCATION: '地点',
  PLANNING_NOTES: '规划说明',
  EVENT_DETAIL: '活动详情',
  ADD_EVENT: '新增活动',
  EDIT_EVENT: '编辑活动',
  DELETE_EVENT: '删除活动',
  STUDENT_PERFORMANCE: '学生表现',
  CLASS_PERFORMANCE: '班级表现',
  NO_NUMERICAL_DATA: '无数据',
}

const teacherDashboard = {
  MY_TEACHING: '我的教学表现',
  MY_STUDENT: '我的学生',
  ORDER_BY: '排序方式',
  BY_NAME: '根据姓名',
  MARKING_STATISTICS: '批改统计',
  TASK_NAME: '作业标题',
  AVG_DAY_BTW_MARKING_DATE_SUBMISSION_DATE: '批改与提交日期之间的平均天数',
  DAYS: '天',
}


const studentOverview = {
  STUDENT_OVERVIEW: '学生概览',
  STUDENT_LIST: '学生列表',
  MENTOR: '导师',
}

const studentDetail = {
  STUDENT_PROFILE: '学生资料',
  OVERVIEW: '概览',
  AT_SCHOOL: '校内',
  EXTERNAL: '校外',
  SOCIAL_BEHAVIOR: '总体表现',
  ACADEMICS: '学术',
  ENROLMENT: '招生',
  TIMETABLE: '课程表',
  ACCELERATING: '加速班',
  ADJUSTMENT: '调整',
  YEARLY_PERFORMANCE: '年度表现',
  NAPLAN_PERFORMANCE: 'NAPLAN总体表现',
  BEHAVIOR_PERFORMANCE: '行为表现',
  STUDENT_YEARLY_PERFORMANCE_OVERVIEW: '学生年度表现概览',
  STUDENT_NAPLAN_YEARLY_PERFORMANCE_OVERVIEW: '学生NPLAN表现概览',
  STUDENT_BEHAVIOR_OVERVIEW: '学生行为表现概览',
  BY_SCORE: '根据分数',
  BY_CLASS: '根据班级',
  STUDENT_AVG_SCORE: '学生平均分数',
  NO_RESULT: '无数据',
  STUDENT_AVG_MARKS_IN_HISTORGRAM: '学生平均分数（柱状图）',
  NO_CLASS_CODE_SELECTED: '班号未选择',
  STUDENT_AVG_MARKS_IN_BOXPLOT: '学生平均分数（箱型图）',
  DETAIL: '详情',
  NUMBER: '数值',
  NO_RESULT_FOUND_FOR: '无数据 -',
  NO_PAT_RESULT_FOUND: '无PAT数据',
  TYPE: '类别',
  COMPLETED_DATE_TIME: '完成日期/时间',
  SCALE: '比例',
  PERCENTILE: '百分位数',
  ACTION: '操作',
  VIEW: '查看',
  STUDENT_SCORE: "学生分数",
  SCHOOL_SCORE: "学校分数",
  STUDENT_SCORE: "学生分数",
  SCHOOL_SCORE: "学校分数",
  QUESTION_NO: '题号',
  PERCENTAGE_CORRECT: '学校平均答对百分比',
  QUESTION_DIFFICULTY: '问题难度',
  CORRECT_ANSWER: '正确答案',
  STUDENT_ANSWER: '学生答案',
  OVERALL_ATTENDANCE_RATE: '整体出勤率',
  HOW_MANY_TIME_LATE_TO_CLASS: '迟到次数',
  MANAGEMENT_OVERVIEW: '管理概览',
  COMMENDATION: '表扬',
  DETENTION: '留堂',
  INCIDENT: '事故',
  SUSPENSION: '被停课',
  DATE: '日期',
  SOCIAL_INDICATOR_VALUE: '社会指标点数',
  CATEGORY: '类别',
  NOTES: '笔记',
  CLASSCODE: '班号',
  STUDENT_PERFORMANCE_IN_TASK_VS_CLASS_PERFORMANCE: '作业表现（学生对比班级）',
  TASK: '作业',
  TASK_TITLE: '作业题目',
  MARKING_FEEDBACK: '批改反馈',
  SCORE: '分数',
  STUDENT_PERFORMANCE_IN_THIS_TEST: '学生在此测验中的整体表现',
  NO_RESULT_FOR: '无数据',
  SUBJECT_TASKS_SUBMISSION_STATUS: '科目作业提交情况',
  SUBMISSION_ON_TIME: '按时提交',
  TOTAL_TASK: '全部作业',
  ON_TIME_RATE: '按时提交率',
  NOT_YET_SUBMITTED: '尚未提交',
  OVER_DUE_SUBMISSION: '逾期提交',
  STUDENT_SCHOOL_TASK_STATUS: '学校作业状态',
  GROWTH_OVERVIEW: '增长率分析',
  TOGGLE_DISPLAY_MODE: '切换展示模式',
  GROWTH: '增长',
  BY_AMOUNT: '根据数量',
  ASSESSMENT_SCORE: '考试分数',
  CLASS_WORK_SCORE: '课题作业分数',
  DERIVED_SCORE: '衍生分数',
  TASKS: '作业数',
  SUBMISSION_RATE: '提交率',
  ON_TIME_SUBMISSION : '按时提交',
  STUDENT_PERFORMANCE_OVERVIEW: '学生总体表现',
  LEGENDS: '说明',
  STRAND: '能力方面',
  STUDENT_RESULT: '学生成绩',
  STUDENT_RESULT_BAND: '学生成绩评级',
  PAT_NAME: 'PAT名称',
  STANDARDISED_TEST: '标准化测试',
  STUDENT_YEARLY_LEARNER_ATTRIBUTE: '年度学习态度总结',
  LEARNER_ATTRIBUTE: '学习态度',
  ALWAYS: '始终',
  USUALLY: '经常',
  SOMETIMES: '偶尔',
  ATTENTION_REQUIRED: '需要注意',
  LEVEL_DESCRIPTION: '评级详情',
}

const schoolExecutives = {
  TASK_SUBMISSION_ANALYSIS: '提交作业分析',
  TASK_PERFORMANCE_ANALYSIS: '作业表现分析',
  FEEDBACK_ANALYSIS: '反馈分析',
  SUBMISSION_ANALYSIS: '提交分析',
  COHORT_ANALYSIS: '队列分析',
  ORDER_STUDENTS_IN_YEAR_LEVEL: '学生依年级排序',
  ADVANCED_SEARCH: '进阶搜索',
  AVG_SUBMISSION: '平均提交',
  AVG_DUE_MARKING_DATE: '平均到期/批改日期',
  AVG_FEEDBACK: '平均反馈',
  TOTAL_WRITTEN_FEEDBACK: '书面反馈总结',
  TOTAL_UNSUBMITTED_TASKS: '未提交作业总结',
  RESULT_BY: '搜索结果',
  ALL_HOME_ROOMS: '所有班号',
  ALL_DOMAINS: '所有学科',
  ALL_SUBJECTS: '所有课程',
  ALL_CLASSES: '所有班级',
  AVG_SUBMISSION_TO_MARKING_DAYS_BY_CLASS_TEACHER: '平均于批改日提交的作业总结（教师对比）',
  AVG_OF_DAYS_BTW_DUE_DATE_AND_MARKING_DATE_BY_CLASS_TEACHER: '平均于截止日和批改日之间提交的作业总结（教师对比）',
  AVG_FEEDBACK_PER_STUDENT_BY_CLASS_TEACHER: '学生平均反馈总结（老师对比）',
  TOTAL_WRITTEN_FEEDBACK_BY_CLASS_TEACHER: '书面反馈总结（教师对比）',
  SUBJECT_WITH_NO_FEEDBACK_YET_BY_CLASS_TEACHER: '当前未获得反馈的科目总结（教师对比）',
  TASKS_LISTED_SIMON_PAM_AS_UNSUBMITTED: 'Tasks listed in Simon/PAM as unsubmitted with due dates in Selected Range by Class Teacher',
  NON_SUBMISSION: '尚未提交的作业',
  UNSUBMITTED_TASKS_BY_SUBJECT: '尚未提交的作业总结（课程对比）',
  UNSUBMITTED_TASKS_BY_STUDENT: '尚未提交的作业总结（学生对比）',
  TOTAL_NON_SUBMISSION_REASON: '未提交的作业原因总结',
  RESULT_FOR: '搜索结果',
  NON_SUBMISSION_BY_CLASS_TEACHER: '尚未提交的作业总结（教师对比）',
  TASKS_LISTED_SIMON_PAM_AS_UNSUBMITTED_BY_SUBJECT_DOMAIN: 'Tasks Listed in Simon/Pam as unsubmitted with Due dates in selected range by subjects/domain (group by class teacher)',
  NAME: '名称',
  REASON: '原因',
  DUE_DATE: '截止日',
  COUNT_NON_SUBMISSION_REASON_BY_STUDENT: '未提交原因总计（学生对比）',
  PERFORMANCE_OVERVIEW: '表现概览',
  MARKS_ACROSS_DOMAIN: '学科分数一览',
  STUDENT_PERFORMANCE_OVERVIEW_BY_DENSITY: '学生表现概览（密度对比）',
  MARKS_ACROSS_DOMAIN_IF_DOMAIN_SELECTED: '学科分数一览（如学科已选择）',
  STUDENT_MARKS_ACROSS_DOMAINS_BY_CLASS_CODE: '学科分数一览（班号对比）',
  STUDENT_MARKS_ACROSS_DOMAINS_BY_YEAR_LEVEL: '学科分数一览（年级对比）',
  STUDENT_MARKS_ACROSS_DOMAINS_BY_DOMAIN: '学科分数一览（学科对比）',
  AVG_SCORE_GRAPH: '学生均分图表展示',
  AVG_SCORE_TABLE: '学生均分列表展示',
  AVG_SCORE_AND_FIRST_TASK_TITLE_BY_NAME: '学生均分（学生名字对比）',
  AVG_SCORE: '平均分',
  CHART_AREA: '图表区域',
  TOP_10_STUDENTS_IN: '前十学生 —— ',
  ROOM_CODE: '班号',
  HIGH_SCORE: '最高分',
  LOW_SCORE: '最低分',
  MEDIUM_SCORE: '中分',
  TOTAL_STUDENTS: '所有学生',
  MARK_DISTRIBUTION: '分数分布',
}

const academicExternal = {
  SCHOOL_PERFORMANCE_IN_THIS_TEST: '学校在此测试中的整体表现',
}

const attendance = {
  ATTENDANCE_RATE: '出勤率',
  BY_WEEK: '以每周作对比',
  BY_MONTH: '以每月作对比',
  SHOW_ONLY_FIRST_TWO_SUBJECTS: '仅展示前两个科目',
}

const behavior = {
  SUMMARY: '总结',
}

const general = {
  USER_ROLE: '权限用户',
  PERMISSION_STRATEGY: '权限对策',
  USER_ROLE_NAME: '权限用户名称',
  ADD_ROLE: '增加权限用户',
  SELECT_EXISTING_USER_ROLE: '选择已有的权限用户',
  CREATE_STRATEGY_FOR: '创建权限给',
  PERMISSION_STRATEGY_NAME: '权限对策名称',
  ADD_STRATEGY: '增加权限',
  SCHOOL_INFORMATION_MANAGEMENT: '学校信息管理',
  EMAIL_SERVICE: '邮箱服务',
  PERMISSION: '权限',
  GENERAL_SETTING: '通用设定',
  SCHOOL_NAME: '学校名称',
  UPLOAD: '上传',
  CURRENT_SCHOOL_LOGO: '当前学校LOGO',
  PREVIEW: '预览',
  REMOVE: '移除',
  SAVE: '保存',
  EMAIL_SERVICE_SETTING: '邮箱服务设置',
  COLOR_BOARD: '主题色',
  EMAIL_ACCOUNT: '邮箱账户',
  EMAIL_PASSWORD: '邮箱密码',
  PERMISSION_SETTING: '权限设定',
  NO_STRATEGY_YET: '无相应权限对策',
  PREVIEW_PERMISSION: '预览权限',
  CREATE_NEW: '创建',
  CURRENT_USER: '当前用户',
  NO_LEVEL: '无阶级',
  EDIT_CURRENT_PERMISSION_LEVEL: '编辑当前权限阶级',
  RENAME_LEVEL: '阶级重命名',
  DELETE_LEVEL: '删除阶级',
  NEW_LEVEL_NAME: '新阶级名称',
  DELETE_LEVEL_CHECK: '您确定删除此阶级吗',
  LOGO_UPLOADED: 'LOGO已上传',
  SCHOOL_INFO_UPDATED: '学校信息已更新',
}

const user = {
  USER_LIST: '用户列表',
  UPDATE_USER: '编辑用户信息',
  CREATE_USER: '创建新用户',
  RESULTS: '条结果',
  CHECK_SIMON: '验证Simon数据',
  MOBILE: '手机',
  PERMISSION_LEVEL: '权限阶级',
  NO_PERMISSION_FOUND: '此学校还未创建任何权限对策',
  RESET_ALL: '重置',
  USER_PERMISSION_SETTING: '用户权限设定',
  DELETE: '删除',
  SEARCH_USER: '搜索用户',
  DELETE_USER_CHECK: '你确定删除此用户吗',
}

const maintenance = {
  SYNC_DATA: '同步数据',
  PREPARE_ATTENDANCE_STATUS_DATA: '处理出勤率状态数据',
  STUDENT_TOKEN_DATA: '学生令牌数据',
  SYNC_STATUS_LIST: '同步状态列表',
  TABLE_NAME: '数据库表',
  RECORDS: '数据记录',
  SYNC_DATE_TIME: '同步日期/时间',
  SYNC_DATA_CHECK: '确定开始数据同步',
  SYNC_DATA_WARNING: '警告：同步数据将需要2小时左右完成同步操作',
  SYNC_NOW: '开始同步',
  ATTENDANCE_STATUS_LIST: '出勤率状态列表',
  ACADEMIC_YEAR: '学年',
  PREPARE_STATUS: '处理状态',
  PREPARE_ATTENDANCE_DATA: '处理出勤率数据',
  PREPARE_NEW_ATTENDANCE_DATA: '处理新出勤率数据',
  PLS_PICK_SEMESTER_FIRST: '请必须在处理出勤率数据前先选择好目标学期',
  UPDATE: '开始更新',
  CONTINUE: '继续',
  CURRENT_STUDENT: '当前学生',
  REMOVE_ALL: '移除所有',
  SELECT_ALL: '勾选所有',
  CONFIRM: '确认',
  CLEAR_FILTER: '清除过滤',
  GENERATE_TOKEN: '生成令牌',
  SELECTED_STUDENT: '已选学生',
  PREPARE_DATA: '准备数据',
  ALL_STUDENTS_SELECTED: '已勾选所有学生',
  STUDENTS_SELECTED: '名学生已勾选',
  NO_STUDENTS_SELECTED_YET: '目前没有勾选学生',
  THE_SELECTED: '已选择的',
  ALREADY_EXISTS_IN_STATUS_LIST: '数据已存在于列表里，是否选择更新此数据',
  PREPARE_ATTENDANCE_DATA_CHECK: '您确定处理出勤率数据 —— ',
  PREPARING_DATA_NOTIFY: '数据筹备中... 处理过程将需要一小时左右，请耐心等待结果',
  SYNC: '同步',
  TABLE: '数据库表',
}

const parentModule = {
  MODULE: '模块',
  MODULE_NAME: '模块名称',
  DISPLAY_STATUS: '显示状态',
  TOGGLE_DISPLAY: '切换显示状态',
  TO_VISIBLE: '显示',
  TO_HIDDEN: '隐藏',
  DISPLAY_STATUS_UPDATED: '显示状态已更新',
}

const advancedSearch = {
  PLS_GIVE_VALUES_TO_ABOVE_DROPDOWN: '班号选择将基于以上下拉菜单选项',
}

export default {
  translation: {
    ...login,
    ...menu,
    ...header,
    ...filter,
    ...subjects,
    ...userProfile,
    ...dashboard,
    ...teacherDashboard,
    ...studentOverview,
    ...studentDetail,
    ...schoolExecutives,
    ...academicExternal,
    ...attendance,
    ...behavior,
    ...general,
    ...user,
    ...maintenance,
    ...parentModule,
    ...advancedSearch,
    lang: 'zh-CN',
    GO_BACK: '返回',
    WELCOME: '您好',
    CANCEL: '取消',
    PLEASE_CONTACT_ADMIN: '请联系系统管理员',
    SHOW_ALL_SUBJECTS: '显示所有科目',
    SHOW_ONLY_ENGLISH_MATH: '仅显示英语/数学',
    YEAR_LEVEL: '年级',
    TEACHER: '教师',
    FIRST_NAME: '名',
    LAST_NAME: '姓',
    SURNAME: '姓',
    STUDENT: '学生',
    ERROR: '失败',
    PLEASE_FILL_IN_ALL_FIELDS: '请确保所有空格处已填写',
    NO: '否',
    YES: '是',
    TODAY: '今天',
    PREV: '往前',
    NEXT: '往后',
    ABSENT_BETWEEN: '缺席于',
    CURRENT: '当前',
    NO_STUDENTS_FOUND: '无法找到相应学生',
    COMPARISION_OF_STUDENT_YEARLY_LEARNER_ATTRIBUTE: '年度学习态度总结对比',
    AUTHENTICATION_EXPIRED: '登入状态超时',
  }
};
