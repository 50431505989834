import { observable, action, makeObservable } from 'mobx';
import CollegeStore from './college-store';
import DataSheetStore from './data-sheet-store';
import UserStore from './user-store';
import BehaviorStore from './behavior-store';
import AcademicStore from './academic-store';
import AttendanceStore from './attendance-store';
import StudentStore from './student-store';
import FilterStore from './filter-store';
import MaintenanceStore from './maintenance-store';
import ParentStore from './parent-store';
import TeacherStore from './teacher-store';


interface WebNotification {
  message: string,
  options?:
  {
    variant: 'success' | 'error' | 'info' | 'warning'
  },
}

export default class RootStore {
  testVal = 'TEST_VALUE';

  destination = 'Select';

  notification: WebNotification = {
    message: "",
    options: {
      variant: "info"
    }
  }

  userStore;
  collegeStore;
  dataSheetStore;
  studentStore;
  filterStore;
  behaviorStore;
  academicStore;
  attendanceStore;
  maintenanceStore;
  parentStore;
  teacherStore;

  constructor() {
    makeObservable(this, {
      testVal: observable,
      destination: observable,
      notification: observable,
      notify: action,
    });
    this.userStore = new UserStore(this);

    this.collegeStore = new CollegeStore(this);
    this.dataSheetStore = new DataSheetStore(this);
    this.filterStore = new FilterStore(this);
    this.behaviorStore = new BehaviorStore(this);
    this.academicStore = new AcademicStore(this);
    this.attendanceStore = new AttendanceStore(this);
    this.studentStore = new StudentStore(this);
    this.maintenanceStore = new MaintenanceStore(this);
    this.parentStore = new ParentStore(this);
    this.teacherStore = new TeacherStore(this);
  }

  notify(msg: string, level?: 'success' | 'error' | 'info' | 'warning') {
    if (level) {
      this.notification = {
        message: msg,
        options: {
          variant: level,
        },
      };
    } else {
      this.notification = {
        message: msg,
      };
    }
  }
}
