import React, {
  createContext, useContext, useState, useEffect, useRef,
} from 'react';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import useStores from '../hooks/use-stores';
import { toJS } from 'mobx';
// import { NOTIFICATION_AUTO_HIDE_DURATION } from '../config/settings';


const NotificationContext = createContext(null);

const NotificationProvider = observer(({ children }) => {
  const { rootStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
  }, []);

  useEffect(() => {

    if (mounted.current && rootStore.notification) {
      if ('message' in rootStore.notification && rootStore.notification.message != '') {
        enqueueSnackbar(rootStore.notification.message,
          {
            ...rootStore.notification.options,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
      }
    }
  }, [rootStore.notification]);

  // Don't wrap SnackbarProvider here.
  // We need to make sure NotificationProvider nests inside SnackbarProvider to access useSnackbar.
  return (
    <NotificationContext.Provider value={null}>{children}</NotificationContext.Provider>
  );
});

export default NotificationProvider;
