import { action, observable, makeObservable, toJS } from 'mobx';
import { Axios } from '../utilities/network';

export default class MaintenanceStore {
    loading: boolean = false;

    loadingStudent: boolean = false;


    syncDataList: any = [];

    studentList: any = [];

    studentFilteredList: any = [];

    schoolAttendanceList: any = [];

    studentPerformanceList: any = [];

    learningQueryList: any = [];

    rootStore;

    constructor(rootStore) {
        makeObservable(this, {
            loading: observable,
            syncDataList: observable,
            studentList: observable,
            studentFilteredList: observable,
            schoolAttendanceList: observable,
            studentPerformanceList: observable,
            learningQueryList: observable,
            getSyncDataStatus: action,
            onSyncData: action,
            getCurrentStudentList: action,
            generateParentLinkToken: action,
            syncDataByTableId: action,
        });
        this.rootStore = rootStore;
    }

    getSyncDataStatus = async () => {
        this.loading = true;
        try {
            const res = await Axios.get(`api/SyncData/GetSyncDataStatus`);
            this.syncDataList = res.data;
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        } finally {
            this.loading = false;
        }
    }

    onSyncData = async () => {
        try {
            const res = await Axios.get(`api/SyncData/SyncData`);
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        }
    }

    getCurrentStudentList = async () => {
        this.loading = true;
        try {
            const res = await Axios.get(`api/SystemManagement/GetCurrentStudentList`);
            this.studentList = res.data;
            this.studentFilteredList = res.data;
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        } finally {
            this.loading = false;
        }
    }

    generateParentLinkToken = async (data: any) => {
        this.loading = true;
        try {
            const res = await Axios.post(`api/SystemManagement/GenerateParentLinkToken`, data);
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        } finally {
            this.loading = false;
        }
    }

    syncDataByTableId = async (id: number) => {
        this.loading = true;
        try {
            const res = await Axios.put(`api/SyncData/SyncDataByTableId/${id}`);
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        } finally {
            this.loading = false;
        }
    }

    // ==================== School Attendance ========================== //

    prepareSchoolAttendanceData = async (semesterId: number) => {
        try {
            const res = await Axios.get(`/api/SyncData/PrepareSchoolAttendanceData/${semesterId}`);
            console.log(res.data);
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        }
    }

    prepareAllSemesterSchoolAttendanceData = async () => {
        try {
            const res = await Axios.get(`/api/SyncData/PrepareAllSemesterSchoolAttendanceData`);
            console.log(res.data);

            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        }
    }

    getSchoolAttendancePrepareStatusList = async () => {
        try {
            const res = await Axios.get(`/api/SyncData/GetSchoolAttendancePrepareStatusList`);
            console.log("School Attendance", res.data);
            this.schoolAttendanceList = res.data;
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        }
    }

    // ==================== Student Performance ========================== //

    prepareStudentOverallScoreAndAttendanceRateBySemesterId = async (semesterId: number) => {
        try {
            const res = await Axios.get(`/api/SyncData/PrepareStudentOverallScoreAndAttendanceRateBySemesterId/${semesterId}`);
            console.log(res.data);
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        }
    }

    prepareAllSemesterStudentOverallScoreAndAttendanceRate = async () => {
        try {
            const res = await Axios.get(`/api/SyncData/PrepareAllSemesterStudentOverallScoreAndAttendanceRate`);
            console.log(res.data);
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        }
    }

    getSyncStudentOverallScoreAndAttendanceRateStatus = async () => {
        try {
            const res = await Axios.get(`/api/SyncData/GetSyncStudentOverallScoreAndAttendanceRateStatus`);
            console.log("Student Performance List", res.data);
            this.studentPerformanceList = res.data;
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        }
    }

    // ==================== Learning Query ========================== //

    updateLearningQueryResultBySemesterId = async (semesterId: number) => {
        try {
            const res = await Axios.get(`/api/SyncData/UpdateLearningQueryResultBySemesterId/${semesterId}`);
            console.log(res.data);
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        }
    }

    updateAllLearningQueryResult = async () => {
        try {
            const res = await Axios.get(`/api/SyncData/UpdateAllLearningQueryResult`);
            console.log(res.data);
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        }
    }

    getSyncLearningQueryResultStatus = async () => {
        try {
            const res = await Axios.get(`/api/SyncData/GetSyncLearningQueryResultStatus`);
            console.log("Learning Query List", res.data);
            this.learningQueryList = res.data;
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        }
    }
}