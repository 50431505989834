import { action, observable, makeObservable } from 'mobx';
import { Axios } from '../utilities/network';

export default class AttendanceStore {

  loading: boolean = false;
  advanceLoading: boolean = false;
  attendanceData = [];
  attendanceAdvanceData = [];
  // selectedYear = "";
  // selectedSemester = "";
  // selectedHomeroom = "";
  // selectedDomain = "";
  // selectedSubject = "";
  // selectedClass = "";
  attendanceTitleFirstLine = "";
  attendanceTitleSecondLine = "";
  attendancePrepareStatusList = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      advanceLoading: observable,
      attendanceData: observable,
      attendanceAdvanceData: observable,
      // selectedYear: observable,
      // selectedSemester: observable,
      // selectedHomeroom: observable,
      // selectedDomain: observable,
      // selectedSubject: observable,
      // selectedClass: observable,
      attendanceTitleFirstLine: observable,
      attendanceTitleSecondLine: observable,
      attendancePrepareStatusList: observable,
      getAttendanceByClassCodes: action,
      getAdvanceAttendanceByClassCodes: action,
      getAttendancePrepareStatusList: action,
      prepareStudentAttendanceData: action,
    });

    this.rootStore = rootStore;
  }

  getAttendanceByClassCodes = async (data) => {
    this.loading = true;

    try {
      const res = await Axios.post(`/api/Attendance/GetStudentAttendanceByPeriodAndClass`, data);
      this.attendanceData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      // this.attendanceData = [];
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  getAdvanceAttendanceByClassCodes = async (data) => {
    this.advanceLoading = true;

    try {
      const res = await Axios.post(`/api/Attendance/GetStudentAttendanceByPeriodAndClass`, data);
      this.attendanceAdvanceData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      // this.attendanceData = [];
      return Promise.resolve(err.data);
    } finally {
      this.advanceLoading = false;
    }
  }

  getAttendancePrepareStatusList = async () => {
    this.loading = true;

    try {
      const res = await Axios.get(`/api/Attendance/GetAttendancePrepareStatusList`);
      console.log("getAttendancePrepareStatusList", res.data);
      this.attendancePrepareStatusList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  prepareStudentAttendanceData = async (semester) => {
    try {
      const res = await Axios.get(`/api/Attendance/PrepareStudentAttendanceData/${semester}`);
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } 
  }  

}