import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Layout from "../shared-layout";
import useStores from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import LoadingPanel from '../loading-panel';
import LockIcon from '@material-ui/icons/Lock';
import { CircularProgress, TextField } from '@material-ui/core';
import loginBg from '../../images/login.jpg';
import { Link, navigate } from 'gatsby';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useTranslation } from 'react-i18next';

const ForgotPasswordPage = observer(({ goLogin }) => {
    const { userStore, rootStore } = useStores();
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>("");
    const [confirmEmail, setConfirmEmail] = useState<string>("");

    const resetPassword = () => {
        if (email == "" || confirmEmail == "") return;
        if (email != confirmEmail) {
            rootStore.notify(t('TWO_EMAILS_NOT_MATCH'), 'warning');
            return;
        }
        userStore.sendResetPasswordEmail({
            email: email,
            status: 1
        }).then(() => {
            rootStore.notify(`${t('VERIFICATION_EMAIL_SENT')}!`, 'success');
            navigate("/");
        }).catch(() => {
            rootStore.notify(`${t('INVALID_EMAIL')}!`, 'error');
        });
    }

    return (
        <>
            <title>{t('FORGOT_PASSWORD')}</title>
            <div
                className="relative grid grid-cols-2 top-1/2 left-1/2 bg-white rounded-lg py-8 px-4 sign-in"
                style={{ transform: "translate(-50%, -50%)" }}
            >

                <div className="relative">
                    <div className='aboslute'>
                        <button onClick={goLogin} className='px-4 text-barPrimary'>
                            <KeyboardBackspaceIcon /> <span className="font-bold pt-2">{t('GO_BACK')}</span>
                        </button>
                    </div>
                    <img
                        className="w-96 h-96 object-cover rounded-full shadow-2xl absolute top-1/2 left-1/2"
                        style={{ transform: "translate(-50%, -50%)" }}
                        src={loginBg}
                        alt="logo"
                    />
                </div>

                <div className="p-8">
                    <div className="mb-2 text-3xl text-barPrimary w-full text-center uppercase" style={{ color: '#cd595f' }}>
                        {t('FORGOT_PASSWORD')}?
                    </div>
                    <div className="mb-4 text-sm text-gray-300 w-full text-center">
                        {t('WELCOME_TO_THE_SYSTEM')}
                    </div>
                    <input
                        type="text"
                        className="p-4 my-2 rounded-3xl bg-blue-50 w-full"
                        placeholder={t('EMAIL')}
                        value={email}
                        onChange={(value) => setEmail(value.target.value as string)}
                    />
                    <input
                        type="text"
                        className="p-4 my-2 rounded-3xl bg-blue-50 w-full"
                        placeholder={t('CONFIRM_EMAIL')}
                        value={confirmEmail}
                        onChange={(value) => setConfirmEmail(value.target.value as string)}
                    />
                    {/* <div className="text-center text-barPrimary text-sm">
                        <button onClick={goLogin}>
                            <u>Go Sign In</u>
                        </button>
                    </div> */}
                    <button
                        type="button"
                        className={`rounded-3xl text-white w-full text-center my-8 ${userStore.loading ? 'bg-white py-2' : 'bg-barPrimary py-4'}`}
                        onClick={resetPassword}
                        disabled={userStore.loading}
                        id="sign-in-button"
                    >
                        {
                            userStore.loading ? <CircularProgress className="text-barPrimary" />
                                :
                                t('SEND_VERIFICATION_EMAIL')
                        }
                    </button>

                </div>

            </div>
        </>
    )
})

export default ForgotPasswordPage;

