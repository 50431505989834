export const isProduction = process.env.NODE_ENV === 'production';//'production';

const prodBackEndUrl =  'https://dashapi.avemaria.vic.edu.au/'; // 'https://iemsapi.vmortech.com.au/';//
const prodFrontEndUrl =  'https://dash.avemaria.vic.edu.au/'; //'https://iems.vmortech.com.au'; //
const prodPictureBedUrl = 'https://dashapi.avemaria.vic.edu.au/Image?id='; //  'https://iemsapi.vmortech.com.au/Image?id='; //
const prodClientId = '145b7aea-63a8-48c4-b454-b3ce3423e34e';
const prodAuthorityUrl = 'https://login.microsoftonline.com/avemaria.vic.edu.au';
const prodRedirectUrl ='https://dash.avemaria.vic.edu.au/'; // 'https://iems.vmortech.com.au'; //

export const backEndBaseURL = isProduction ? prodBackEndUrl : 'https://dashapi.avemaria.vic.edu.au/'//'http://localhost:52012'//https://iemsapi.vmortech.com.au'//
export const frontEndBaseUrl = isProduction ? prodFrontEndUrl : 'http://localhost:3000';
export const imageURLPrefix = isProduction ? prodPictureBedUrl : 'https://dashapi.avemaria.vic.edu.au/Image?id=';
export const msalClientId = isProduction ? prodClientId : '0328cbd3-587a-417e-ac9a-2a26bdefec9a';
export const msalAuthorityUrl = isProduction ? prodAuthorityUrl : 'https://login.microsoftonline.com/d03d0041-c627-4c27-a07c-0e47221f336d';
export const msalRedirectUrl = isProduction ? prodRedirectUrl : 'http://localhost:8000';