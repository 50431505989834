import axios from 'axios';
import { action, observable, makeObservable, runInAction, toJS } from 'mobx';
import { Axios } from '../utilities/network';

export default class StudentStore {

  loading: boolean = false;
  loadingYearlyPerformance: boolean = false;
  loadingOverview: boolean = false;
  loadingStudentShortList: boolean = false;
  loadingStudentSearch: boolean = false;
  loadingPAT: boolean = false;
  loadingPATDetail: boolean = false;
  loadingNaplanDetail: boolean = false;
  loadingSDashNaplan: boolean = false;
  naplanLoading: boolean = false;
  loadingLearnerAttribute: boolean = false;
  loadingYearlyAttribute: boolean = false;
  studentData: any = [];
  atSchoolData: any = [];
  behaviorData: any = [];
  attendanceData: any = [];
  studentDetail: any = [];
  studentIndividualRecord: any = [];
  naplanData: any = [];
  dashboardData: any = { studentNumberByYearLevel: [] };
  currentStudent: number = 0;
  currentStudentId: number = 0;
  studentShortList: any = [];
  sPatOverviewData: any = [];
  sPatDetailData: any = [];
  schoolNaplanData: any = [];
  sOverviewBehaviorData: any = [];
  sOverviewNaplanData: any = [];
  sOverviewDomainData: any = [];
  sScoreAndAttendanceData: any = [];
  naplanDetailData: any = [];
  studentScoreDetail: any = [];
  sPATNaplanList: any = { naplanList: [], patList: [] };
  schoolDashboardDomainData: any = [];  
  studentStaffOverviewData: any = null;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      loadingYearlyPerformance: observable,
      loadingOverview: observable,
      loadingStudentShortList: observable,
      loadingStudentSearch: observable,
      loadingNaplanDetail: observable,
      loadingPAT: observable,
      loadingPATDetail: observable,
      loadingSDashNaplan: observable,
      naplanLoading: observable,
      loadingYearlyAttribute: observable,
      loadingLearnerAttribute: observable,
      studentData: observable,
      atSchoolData: observable,
      behaviorData: observable,
      attendanceData: observable,
      studentDetail: observable,
      naplanData: observable,
      dashboardData: observable,
      currentStudent: observable,
      studentShortList: observable,
      studentIndividualRecord: observable,
      currentStudentId: observable,
      sPatOverviewData: observable,
      sPatDetailData: observable,
      schoolNaplanData: observable,
      sOverviewNaplanData: observable,
      sOverviewBehaviorData: observable,
      sOverviewDomainData: observable,
      sScoreAndAttendanceData: observable,
      naplanDetailData: observable,
      studentScoreDetail: observable,
      sPATNaplanList: observable,
      schoolDashboardDomainData: observable,
      studentStaffOverviewData: observable,
      getStudentList: action,
      getStudentAcadamicAtSchool: action,
      getStudentBehavior: action,
      getStudentAttendance: action,
      getNaplanData: action,
      searchStudent: action,
      getDashboardData: action,
      getDashboardSchoolDomainMarkData: action,
      getDashboardSchoolNaplanData: action,
      getStudentPATOverview: action,
      getStudentPATDetail: action,
      getStudentOverallScoreAttendanceRate: action,
      getStudentTaskOverview: action,
      getStudentScoreDetail: action,
      getIndividualStudentOverviewBehaviorData: action,
      getIndividualStudentOverviewDomainData: action,
      getIndividualStudentOverviewNaplanData: action,
      getStudentLearnerAttributeBySubjectClass: action,
      getStudentYearlyLearnerAttribute: action,
      getStudentYearlyLearnerAttributeBySubject: action,
    });

    this.rootStore = rootStore;
  }

  getStudentList = async (data) => {
    this.loadingOverview = true;
    try {
      const res = await Axios.post(`api/Student/GetStudentList`, data);
      this.studentData = res.data;
      console.log("Student overview", res.data);

      return Promise.resolve(res.data);
    } catch (err) {
      // this.behaviorData = [];
      return Promise.resolve(err.data);
    } finally {
      this.loadingOverview = false;
    }
  }

  getStudentBehavior = async (req) => {
    this.loading = true;
    try {
      const res = await Axios.post(`api/Student/GetStudentBehavior`, req);
      console.log("student behavior", res.data);
      this.behaviorData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loading = false;
    }
  }

  getStudentAttendance = async (req) => {
    this.loading = true;
    try {
      const res = await Axios.post("api/Student/GetStudentAttendance", req);
      console.log(res.data);
      this.attendanceData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loading = false;
    }
  }

  getStudentAcadamicAtSchool = async (data) => {
    this.loading = true;
    try {
      const res = await Axios.post(`api/Student/GetStudentAcademicAtSchoolData`, data);
      this.atSchoolData = res.data;
      this.currentStudent = data.uid;
      console.log("atschool", res.data);

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loading = false;
    }
  }

  searchStudent = async (req: any) => {
    this.loadingStudentSearch = true;
    try {
      const res = await Axios.post(`api/Student/SearchStudentByIdOrName`, req);
      this.studentDetail = res.data;
      if (res.data.length > 0) {
        this.studentIndividualRecord = res.data;
      }
      console.log("Search student", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loadingStudentSearch = false;
    }
  }

  filterStudentList = async (req) => {
    runInAction(() => {
      this.loading = true;
    })

    try {
      const res = await Axios.post(`api/Student/GetStudentList/`, req);
      this.studentDetail = res.data;
      console.log("Search student", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  filterStudentShortList = async (req) => {
    runInAction(() => {
      this.loadingStudentShortList = true;
    })

    try {
      const res = await Axios.post(`api/Student/GetStudentShortList/`, req);
      this.studentShortList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      runInAction(() => {
        this.loadingStudentShortList = false;
      })
    }
  }

  getNaplanData = async (id) => {
    this.naplanLoading = true;
    try {
      const res = await Axios.get(`api/Student/GetStudentNaplanData/${id}`);
      this.naplanData = res.data;
      console.log("Student naplan data", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.naplanLoading = false;
    }
  }

  getDashboardData = async (semesterId: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/Student/GetDashboardData/${semesterId}`);
      this.dashboardData = res.data;
      this.studentStaffOverviewData = res.data;
      console.log("dashboard", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loading = false;
    }
  }

  getDashboardSchoolDomainMarkData = async () => {
    this.loadingYearlyPerformance = true;
    try {
      const res = await Axios.get(`api/Student/GetDashboardSchoolDomainMarkData/`);
      this.schoolDashboardDomainData = res.data;
      console.log("school domain mark dashboard", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loadingYearlyPerformance = false;
    }
  }

  getDashboardSchoolNaplanData = async () => {
    this.loadingSDashNaplan = true;
    try {
      const res = await Axios.get(`api/Student/GetDashboardSchoolNaplanData/`);
      console.log("dashboard naplan", res.data);
      this.schoolNaplanData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loadingSDashNaplan = false;
    }
  }

  getIndividualStudentOverviewBehaviorData = async (uid: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/Student/GetStudentOverviewBehaviorData/${uid}`);
      console.log("Student Behavior overview", res.data);
      this.sOverviewBehaviorData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loading = false;
    }
  }

  getIndividualStudentOverviewNaplanData = async (uid: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/Student/GetStudentOverviewNaplanData/${uid}`);
      console.log("Student Naplan overview", res.data);
      this.sOverviewNaplanData = res.data;
      console.log("Student Naplan overview 2", toJS(this.sOverviewNaplanData));
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loading = false;
    }
  }

  getStudentNaplanResponses = async (resultId: number, strandId: number) => {
    this.loadingNaplanDetail = true;
    try {
      const res = await Axios.get(`api/Student/GetStudentNaplanResponses/${resultId}/${strandId}`);
      console.log("Student Naplan Response", res.data);
      this.naplanDetailData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loadingNaplanDetail = false;
    }
  }

  getIndividualStudentOverviewDomainData = async (uid: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/Student/GetStudentOverviewDomainMarkData/${uid}`);
      console.log("Student Domain Overview", res.data);
      this.sOverviewDomainData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loading = false;
    }
  }

  getStudentPATOverview = async (uid: number) => {
    this.loadingPAT = true;
    try {
      const res = await Axios.get(`api/Student/GetStudentPATOverview/${uid}`);
      console.log("Student PAT Overview", res.data);
      this.sPatOverviewData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loadingPAT = false;
    }
  }

  getStudentPATDetail = async (uid: number) => {
    this.loadingPATDetail = true;
    try {
      const res = await Axios.get(`api/Student/GetStudentPATDetail/${uid}`);
      console.log("Student PAT Detail", res.data);
      this.sPatDetailData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loadingPATDetail = false;
    }
  }

  getStudentTaskOverview = async (req: any) => {
    try {
      const res = await Axios.post(`api/Student/GetStudentTaskOverview`, req);
      console.log("Student Task Overview", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    }
  }

  getStudentOverallScoreAttendanceRate = async (req: any) => {
    try {
      const res = await Axios.post(`api/Student/GetStudentOverallScoreAndAttendanceRate`, req);
      console.log("Student Overall Attendance", res.data);
      this.sScoreAndAttendanceData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    }
  }

  getStudentScoreDetail = async (req: any) => {
    try {
      const res = await Axios.post(`api/Student/GetStudentScoreDetail`, req);
      console.log("Student Score Detail", res.data);
      this.studentScoreDetail = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } 
  }

  getStudentOverviewPATAndNaplanDataList = async (uid: any) => {
    try {
      const res = await Axios.post(`api/Student/GetStudentOverviewPATAndNaplanDataList?uid=${uid}`);
      console.log("Student PAT & Naplan List", res.data);
      this.sPATNaplanList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } 
  }

  getStudentLearnerAttributeBySubjectClass = async (studentId: number, subjectClassId: number) => {
    this.loadingLearnerAttribute = true;
    try {
      const res = await Axios.get(`api/Student/GetStudentLearnerAttributeBySubjectClass/${studentId}/${subjectClassId}`);
      console.log("Learner Attribute List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loadingLearnerAttribute = false;
    }
  }

  getStudentYearlyLearnerAttribute = async (uid: number) => {
    this.loadingYearlyAttribute = true;
    try {
      const res = await Axios.get(`api/Student/GetStudentYearlyLearnerAttribute/${uid}`);
      console.log("Yearly Learner Attribute List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loadingYearlyAttribute = false;
    }
  }

    getStudentYearlyLearnerAttributeBySubject = async (uid: number, subjectClassId: number) => {
    this.loadingYearlyAttribute = true;
    try {
      const res = await Axios.get(`api/Student/GetStudentYearlyLearnerAttributeBySubject/${uid}/${subjectClassId}`);
      console.log("Yearly Learner Attribute List By Subject Class", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loadingYearlyAttribute = false;
    }
  }

}
