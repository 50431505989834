import React from 'react';
import { enableStaticRendering } from 'mobx-react';
import RootStore from './root-store';
import UserStore from './user-store';
import CollegeStore from './college-store';
import DataSheetStore from './data-sheet-store';
import BehaviorStore from './behavior-store';
import AcademicStore from './academic-store';
import AttendanceStore from './attendance-store';
import StudentStore from './student-store';
import FilterStore from './filter-store';
import MaintenanceStore from './maintenance-store';
import ParentStore from './parent-store';
import TeacherStore from './teacher-store';


type CompositeStore = {
  rootStore: RootStore,
  userStore: UserStore,
  collegeStore: CollegeStore,
  dataSheetStore: DataSheetStore,
  behaviorStore: BehaviorStore,
  academicStore: AcademicStore,
  attendanceStore: AttendanceStore,
  studentStore:StudentStore,
  filterStore: FilterStore,
  maintenanceStore: MaintenanceStore,
  parentStore: ParentStore,
  teacherStore: TeacherStore,
};

const isServer = typeof window === 'undefined';
// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(isServer);

// let _stores: null | CompositeStore = null;

const initStores = () => {
  const rootStore = new RootStore();
  const initializedStores: CompositeStore = {
    rootStore,
    userStore: rootStore.userStore,
    collegeStore: rootStore.collegeStore,
    dataSheetStore: rootStore.dataSheetStore,
    behaviorStore: rootStore.behaviorStore,
    academicStore: rootStore.academicStore,
    attendanceStore: rootStore.attendanceStore,
    studentStore:rootStore.studentStore,
    filterStore: rootStore.filterStore,
    maintenanceStore: rootStore.maintenanceStore,
    parentStore: rootStore.parentStore,
    teacherStore: rootStore.teacherStore,
  };
  return initializedStores;
};

export const stores = initStores(); // _stores || initStores();

export const StoreContext = React.createContext(stores);

export const StoreProvider = ({ children }) => <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>;
