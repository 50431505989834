// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academics-index-tsx": () => import("./../../../src/pages/academics/index.tsx" /* webpackChunkName: "component---src-pages-academics-index-tsx" */),
  "component---src-pages-attendence-index-tsx": () => import("./../../../src/pages/attendence/index.tsx" /* webpackChunkName: "component---src-pages-attendence-index-tsx" */),
  "component---src-pages-behavioral-index-tsx": () => import("./../../../src/pages/behavioral/index.tsx" /* webpackChunkName: "component---src-pages-behavioral-index-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-data-management-index-tsx": () => import("./../../../src/pages/data-management/index.tsx" /* webpackChunkName: "component---src-pages-data-management-index-tsx" */),
  "component---src-pages-error-message-index-tsx": () => import("./../../../src/pages/error-message/index.tsx" /* webpackChunkName: "component---src-pages-error-message-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-platform-management-schools-tsx": () => import("./../../../src/pages/platform-management/schools.tsx" /* webpackChunkName: "component---src-pages-platform-management-schools-tsx" */),
  "component---src-pages-school-dashboard-tsx": () => import("./../../../src/pages/school-dashboard.tsx" /* webpackChunkName: "component---src-pages-school-dashboard-tsx" */),
  "component---src-pages-school-executives-index-tsx": () => import("./../../../src/pages/school-executives/index.tsx" /* webpackChunkName: "component---src-pages-school-executives-index-tsx" */),
  "component---src-pages-student-detail-index-tsx": () => import("./../../../src/pages/student-detail/index.tsx" /* webpackChunkName: "component---src-pages-student-detail-index-tsx" */),
  "component---src-pages-student-overview-tsx": () => import("./../../../src/pages/student-overview.tsx" /* webpackChunkName: "component---src-pages-student-overview-tsx" */),
  "component---src-pages-student-tasks-index-tsx": () => import("./../../../src/pages/student-tasks/index.tsx" /* webpackChunkName: "component---src-pages-student-tasks-index-tsx" */),
  "component---src-pages-system-management-general-tsx": () => import("./../../../src/pages/system-management/general.tsx" /* webpackChunkName: "component---src-pages-system-management-general-tsx" */),
  "component---src-pages-system-management-maintenance-tsx": () => import("./../../../src/pages/system-management/maintenance.tsx" /* webpackChunkName: "component---src-pages-system-management-maintenance-tsx" */),
  "component---src-pages-system-management-parent-module-tsx": () => import("./../../../src/pages/system-management/parent-module.tsx" /* webpackChunkName: "component---src-pages-system-management-parent-module-tsx" */),
  "component---src-pages-system-management-user-tsx": () => import("./../../../src/pages/system-management/user.tsx" /* webpackChunkName: "component---src-pages-system-management-user-tsx" */),
  "component---src-pages-user-tsx": () => import("./../../../src/pages/user.tsx" /* webpackChunkName: "component---src-pages-user-tsx" */)
}

