import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import useStores from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import loginBg from '../../images/login.jpg';
import { CircularProgress } from '@material-ui/core';
import { Link, navigate } from 'gatsby';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { signInMethod } from '../../constants/options';
import { useTranslation } from 'react-i18next';

const VmorSignInPage = observer(({ callback, goForgotPassword }) => {
    const { rootStore, userStore } = useStores();
    const { t } = useTranslation();
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const signIn = () => {
      if (username == "" || password == "") return;
      if (username == " " || password == " ") return;
  
      userStore.signIn({
        username,
        password
      }).then(() => {
        localStorage.SIGN_IN_METHOD = signInMethod.traditional;
        if (typeof window !== "undefined" && localStorage.USER_INFO)
          rootStore.notify(`${t('WELCOME')} ` + JSON.parse(localStorage.USER_INFO).firstName + "!", 'success');
        navigate("/dashboard");
      }).catch(() => {
        rootStore.notify(t('INCORRECT_USERNAME_PASSWORD'), 'error');
      });
    }
  
    return (
      <div
        className="relative grid grid-cols-2 top-1/2 left-1/2 bg-white rounded-lg py-8 px-4 sign-in"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div className="relative">
          <div className='aboslute'>
            <button onClick={callback} className='px-4 text-barPrimary'>
              <KeyboardBackspaceIcon /> <span className="font-bold">{t('GO_BACK')}</span>
            </button>
          </div>
          <img
            className="w-96 h-96 object-cover rounded-full shadow-2xl absolute top-1/2 left-1/2"
            style={{ transform: "translate(-50%, -50%)" }}
            src={loginBg}
            alt="logo"
          />
        </div>
  
        <div className="p-8">
          <div className="mb-2 text-3xl text-barPrimary w-full text-center" style={{ color: '#cd595f' }}>
            {t('SIGN_IN_NOW')}
          </div>
          <div className="mb-4 text-sm text-gray-300 w-full text-center">
            {t('WELCOME_TO_THE_SYSTEM')}
          </div>
          <input
            type="text"
            className="p-4 my-2 rounded-3xl bg-blue-50 w-full"
            placeholder={t('USERNAME')}
            value={username}
            onChange={(value) => setUsername(value.target.value as string)}
          />
          <input
            type="password"
            className="p-4 my-2 rounded-3xl bg-blue-50 w-full"
            placeholder={t('PASSWORD')}
            value={password}
            onChange={(value) => setPassword(value.target.value as string)}
          />
          <div className="text-center text-barPrimary text-sm">
            <button onClick={goForgotPassword}>
              <u>{t('FORGOT_PASSWORD')}?</u>
            </button>
          </div>
          <button
            type="button"
            className={`rounded-3xl text-white w-full text-center my-8 ${userStore.loading ? 'bg-white py-2' : 'bg-barPrimary py-4'}`}
            onClick={signIn}
            disabled={userStore.loading}
            id="sign-in-button"
          >
            {
              userStore.loading ? <CircularProgress className="text-barPrimary" />
                :
                t('SIGN_IN')
            }
          </button>
        </div>
      </div>
    )
  });

  export default VmorSignInPage;