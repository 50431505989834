/* eslint-disable no-unused-vars */
import {
    action, computed, observable, runInAction, toJS, makeObservable,
  } from 'mobx';
  import { Axios } from '../utilities/network';
  import { parseValidationError } from '../utilities/parse-validation-error';
  import { composeImageUrlsInCartItems, ImageSize } from '../utilities/image-url-composer';
  import { isNative, isWeb } from '../utilities/platform';
  import atob from '../utilities/atob';
  import RootStore from './root-store';
  
  
  
  export default class DataSheetStore {
    tableList = [];
  
    rootStore: RootStore;
  
    constructor(rootStore: RootStore) {
      makeObservable(this, {
          tableList: observable,

      });
  
      this.rootStore = rootStore;
    }
  
    uploadData = async (data) => {
        try {
            const res = await Axios.post("/api/PlatformManagement/UploadExcelData", data);
     
            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    getTableList = async () => {
        try {
            const res = await Axios.get("/api/PlatformManagement/GetTableList");
            this.tableList = res.data;
            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    }
  }
  