/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import { parseValidationError } from '../utilities/parse-validation-error';
import { composeImageUrlsInCartItems, ImageSize } from '../utilities/image-url-composer';
import { isNative, isWeb } from '../utilities/platform';
import atob from '../utilities/atob';
import RootStore from './root-store';
import { THEME_NAME_CONVERSION } from '../constants/style';
import { Language, signInMethod } from '../constants/options';
import { useTranslation } from 'react-i18next';

const isServer = typeof window === 'undefined';

export interface UserInfo {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  confirmedPassword: string,
  email: string;
  phone: string;
  role: number;
  level: number;
  agencyId: number;
  themeClass: string;
}

export interface BaseUserInfo {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  mobile: string;
  collegeId: number;
  themeClass: string;
}

interface LogInRequest {
  username: string;
  password: string;
}

export const predefinedRoles = [
  {
    label: 'customer',
    id: 1,
  },
  {
    label: 'agent',
    id: 2,
  },
];

export default class UserStore {
  get isAuthed() {
    if (typeof window !== "undefined" && localStorage.PERMISSIONS)
      return true;
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  set isAuthed(value) {
    console.log('Deleting token...');
    if (!value && isWeb) {
      delete localStorage.USER_TOKEN;
      delete localStorage.PERMISSIONS;
      delete localStorage.PAGE;
      delete localStorage.THEME;
      delete localStorage.USER_INFO;
      delete localStorage.COLLEGE;
      delete localStorage.CALENDAR_EVENTS;
    }
  }

  currentTheme = "";

  userInfo: BaseUserInfo = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    username: '',
    password: '',
    collegeId: 0,
    themeClass: 'theme-ocean',
  };

  selectedUserInfo: BaseUserInfo = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    username: '',
    password: '',
    collegeId: 0,
    themeClass: 'theme-primary',
  };

  errors: any = {};

  userToken: string | null = null;

  isRestoringToken: boolean = false;

  lang: number = 1;

  agencyId: number | null = null;

  loading: boolean = false;

  loadingList: boolean = false;

  loadingPermission: boolean = false;

  loadingTokenValidation: boolean = false;

  userList: any = { data: [], total: 0 };

  userPermissionList = [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isAuthed: computed,
      currentTheme: observable,
      userInfo: observable,
      selectedUserInfo: observable,
      userList: observable,
      loading: observable,
      loadingList: observable,
      loadingPermission: observable,
      loadingTokenValidation: observable,
      userPermissionList: observable,
      signIn: action,
      signOut: action,
      getTokenByMsalId: action,
      getUserList: action,
      searchUser: action,
      createUser: action,
      resetPassword: action,
      updateUserStatus: action,
      mapSimonTeacher: action,
      sendResetPasswordEmail: action,
      resetPasswordBeforeSignIn: action,
      checkValidUserToken: action,
    });

    this.rootStore = rootStore;
  }

  signIn = async (userInfo: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`authorization/signIn`, userInfo);
      const token = `Bearer ${res.data.token}`;
      console.log(res.data);
      this.userToken = res.data.token;
      localStorage.USER_TOKEN = token;
      localStorage.PERMISSIONS = JSON.stringify(res.data.permissions);
      let rtnCollegeInfo = {
        collegeId: res.data.collegeId,
        logo: res.data.logo
      }
      localStorage.COLLEGE = JSON.stringify(rtnCollegeInfo);
      let rtnUserInfo = {
        title: res.data.title,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        email: res.data.email,
        photo: res.data.photo,
        uid: res.data.uid,
      }
      localStorage.USER_INFO = JSON.stringify(rtnUserInfo);

      for (var theme of THEME_NAME_CONVERSION) {
        if (theme.default == res.data.styleSetting) {
          localStorage.THEME = theme.css;
          break;
        }
      }

      this.userInfo.collegeId = res.data.collegeId;
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      this.isAuthed = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  signOut = (beforeSignIn?: boolean) => {
    if (beforeSignIn || (typeof window !== "undefined" && localStorage.SIGN_IN_METHOD == signInMethod.traditional)) {
      if (beforeSignIn) {
        let msg = localStorage.LANG == Language.CHINESE_TRAD ? '密碼已重置' : Language.CHINESE_SIMP ? '密码已重置' : 'Reset Password Success'
        this.rootStore.notify(msg, "success");
      } else {
        this.rootStore.notify(`Bye!`, "success");
      }
      window.location.href = `/`; // or put the redirect in the header
    }
    this.isAuthed = false;
    this.userToken = "";
  };

  getTokenByMsalId = async (email: any) => {
    runInAction(() => {
      this.loading = true;
    })
    try {
      const res = await Axios.post(`authorization/GetToken`, email);
      const token = `Bearer ${res.data.token}`;
      console.log(res.data);
      this.userToken = res.data.token;
      console.log(this.userToken);

      localStorage.setItem('USER_TOKEN', token);
      localStorage.USER_TOKEN = token;
      localStorage.PERMISSIONS = JSON.stringify(res.data.permissions);
      let rtnCollegeInfo = {
        collegeId: res.data.collegeId,
        logo: res.data.logo
      }
      localStorage.COLLEGE = JSON.stringify(rtnCollegeInfo);
      let rtnUserInfo = {
        title: res.data.title,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        email: res.data.email,
        photo: res.data.photo,
        uid: res.data.uid,
      }
      localStorage.USER_INFO = JSON.stringify(rtnUserInfo);

      for (var theme of THEME_NAME_CONVERSION) {
        if (theme.default == res.data.styleSetting) {
          localStorage.THEME = theme.css;
          break;
        }
      }
      this.userInfo.collegeId = res.data.collegeId;
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      this.isAuthed = false;
      return Promise.reject(err);
    } finally {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  getUserList = async (pageSize: number, skip: number) => {
    runInAction(() => {
      this.loadingList = true;
    })

    try {
      const res = await Axios.get(`api/PlatformManagement/GetUserList/${pageSize}/${skip}`);
      this.userList.data = res.data.data;
      this.userList.total = res.data.total;
      console.log(toJS(this.userList));
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      runInAction(() => {
        this.loadingList = false;
      })
    }
  }

  searchUser = async (searchStr: string) => {
    runInAction(() => {
      this.loadingList = true;
    })

    try {
      const res = await Axios.get(`api/PlatformManagement/SearchUser/${searchStr}`);
      this.userList.data = res.data.data;
      this.userList.total = res.data.total;
      console.log(toJS(this.userList));
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      runInAction(() => {
        this.loadingList = false;
      })
    }
  }

  createUser = async (userInfo: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`api/PlatformManagement/CreateUser`, userInfo);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateUser = async (userInfo: any) => {
    this.loading = true;
    console.log(userInfo);

    try {
      const res = await Axios.put(`api/PlatformManagement/UpdateUser/`, userInfo);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  resetPassword = async (userInfo: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`api/PlatformManagement/ResetUserPassword/`, userInfo);
      console.log(res.data);

      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateUserStatus = async (userId: number) => {
    this.loading = true;
    try {
      const res = await Axios.put(`api/PlatformManagement/UpdateUserStatus/${userId}`);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getUserPermission = async (userId: number) => {
    this.loadingPermission = true;
    try {
      const res = await Axios.get(`api/PlatformManagement/GetUserPermission/${userId}`);
      this.userPermissionList = res.data;
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loadingPermission = false;
    }
  }

  updateUserPermission = async (userId: number, permissionInfo: any) => {
    console.log("PermissionInfo", permissionInfo);

    this.loading = true;
    try {
      const res = await Axios.put(`api/PlatformManagement/UpdateUserPermission/${userId}`, permissionInfo);
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  setSelectedUser = (userInfo: BaseUserInfo) => {
    this.selectedUserInfo = userInfo;
  }

  mapSimonTeacher = async (firstName: string, lastName: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/SystemManagement/MapSimonTeacherData/${firstName}/${lastName}`);
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  sendResetPasswordEmail = async (authModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`Email/SendResetPasswordEmail`, authModel);
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  resetPasswordBeforeSignIn = async (authModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`Authentication/ResetPassword/`, authModel);
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  checkValidUserToken = async () => {
    this.loadingTokenValidation = true;
    try {
      const res = await Axios.get(`Authentication/CheckValidUserToken/`);
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loadingTokenValidation = false;
    }
  }

}
