import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Layout from "../shared-layout";
import useStores from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import LoadingPanel from '../loading-panel';
import LockIcon from '@material-ui/icons/Lock';
import { CircularProgress, TextField } from '@material-ui/core';
import loginBg from '../../images/login.jpg';
import { Link, navigate } from 'gatsby';
import { signInMethod } from '../../constants/options';
import { useTranslation } from 'react-i18next';


const ResetPasswordPage = observer(({ token }) => {
    const { userStore, rootStore } = useStores();
    const { t } = useTranslation();
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    useEffect(() => {
        // if (!token || !) navigate("/");
        if (token && token != "") {
            localStorage.USER_TOKEN = `Bearer ${token}`;
            userStore.checkValidUserToken().catch(() => {
                rootStore.notify("This link was expired!", 'error');
                navigate("/");
            });
        }
    }, [token]);

    const resetPassword = () => {
        if (password == "" || confirmPassword == "") return;
        if (password != confirmPassword) {
            rootStore.notify(t('TWO_PASSWORDS_NOT_MATCH'), 'warning');
            return;
        }
        userStore.resetPasswordBeforeSignIn({ password })
            .then(() => {
                userStore.signOut(true);
                localStorage.SIGN_IN_METHOD = signInMethod.sso;
            }).catch(() => {
                rootStore.notify(`${t('RESET_PASSWORD_FAILED')} ${t('PLEASE_CONTACT_ADMIN')}!`, 'error');
            });

    }

    return (
        <>
            <title>{t('RESET_YOUR_PASSWORD')}</title>
            {
                userStore.loadingTokenValidation ?
                    <div
                        className="relative top-1/2 left-1/2 bg-white p-20 rounded-lg text-center"
                        style={{ transform: "translate(-50%, -50%)", width: 'fit-content' }}>
                        <CircularProgress className="text-barPrimary" />
                        <div className="text-barPrimary mt-6">{t('VERIFY_URL_LINK')}...</div>
                    </div>
                    :
                    <div
                        className="relative grid grid-cols-2 top-1/2 left-1/2 bg-white rounded-lg py-8 px-4 sign-in"
                        style={{ transform: "translate(-50%, -50%)" }}
                    >

                        <div className="relative">
                            <img
                                className="w-96 h-96 object-cover rounded-full shadow-2xl absolute top-1/2 left-1/2"
                                style={{ transform: "translate(-50%, -50%)" }}
                                src={loginBg}
                                alt="logo"
                            />
                        </div>

                        <div className="p-8">
                            <div className="mb-2 text-3xl text-barPrimary w-full text-center" style={{ color: '#cd595f' }}>
                                {t('FORGOT_PASSWORD')}?
                            </div>
                            <div className="mb-4 text-sm text-gray-300 w-full text-center">
                                {t('WELCOME_TO_THE_SYSTEM')}
                            </div>
                            <input
                                type="password"
                                className="p-4 my-2 rounded-3xl bg-blue-50 w-full"
                                placeholder={t('PASSWORD')}
                                value={password}
                                onChange={(value) => setPassword(value.target.value as string)}
                            />
                            <input
                                type="password"
                                className="p-4 my-2 rounded-3xl bg-blue-50 w-full"
                                placeholder={t('COMFIRM_PASSWORD')}
                                value={confirmPassword}
                                onChange={(value) => setConfirmPassword(value.target.value as string)}
                            />
                            <div className="text-center text-barPrimary text-sm">
                                <Link to="/">
                                    <u>Go Sign In</u>
                                </Link>
                            </div>
                            <Button
                                className={`rounded-3xl text-white w-full text-right my-8 ${userStore.loading ? 'bg-gray-100 py-2' : 'bg-barPrimary py-4'}`}
                                component="span"
                                onClick={resetPassword}
                                disabled={userStore.loading}
                            >
                                {
                                    userStore.loading ? <CircularProgress className="text-barPrimary" />
                                        :
                                        t('RESET_PASSWORD')
                                }
                            </Button>

                        </div>

                    </div>
            }
        </>
    )
})

export default ResetPasswordPage;

