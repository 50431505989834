import React, { useState } from "react";
import { Button, Select } from "@material-ui/core";
import { Checkbox } from '@material-ui/core';

const CustomisedModal = ({ children, showModal }) => {
    const modalStyle = `fixed z-10 w-full top-0 bottom-0 right-0 overflow-auto`

    return (
        <div
            className={`${!showModal && 'hidden'} ${modalStyle}`}
            style={{ background: 'rgb(0,0,0,0.7)' }}
        >
            {children}
        </div>
    )
}

export default CustomisedModal;