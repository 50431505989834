import { action, observable, makeObservable } from 'mobx';
import { Axios } from '../utilities/network';

export default class BehaviorStore {

  loading: boolean = false;

  behaviorData = [];

  behaviorAdvanceData = [];

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      behaviorData: observable,
      behaviorAdvanceData: observable,
      getBehaviorOverview: action,
      getBehaviorAdvanceData: action,
    });

    this.rootStore = rootStore;
  }

  getBehaviorOverview = async (data) => {
    this.loading = true;
    console.log("Start");
    
    try {
      const res = await Axios.post(`api/Behavior/GetBehaviorOverView`, data);
      this.behaviorData = res.data.data;
      console.log(res.data.data);
      return Promise.resolve(res.data);
    } catch (err) {
      // this.behaviorData = [];
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  getBehaviorAdvanceData = async (data) => {
    this.loading = true;
    try {
      const res = await Axios.post(`api/Behavior/GetBehaviorAdvanceData`, data);
      this.behaviorAdvanceData = res.data.data;
      console.log(res.data.data);
      return Promise.resolve(res.data);
    } catch (err) {
      // this.behaviorData = [];
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }    
  }
}