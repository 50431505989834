
const login = {
  SIGN_IN: '登錄',
  SIGN_IN_NOW: '登錄界面',
  WELCOME_TO_THE_SYSTEM: '歡迎來到學校管理系統',
  FORGOT_PASSWORD: '忘記密碼',
  USERNAME: '用戶名',
  PASSWORD: '密碼',
  CONFIRM_PASSWORD: '確認您的密碼',
  TWO_PASSWORDS_NOT_MATCH: '已輸入密碼不匹配',
  INCORRECT_USERNAME_PASSWORD: '用戶名/密碼錯誤',
  EMAIL: '電郵',
  CONFIRM_EMAIL: '確認您的電郵',
  TWO_EMAILS_NOT_MATCH: '已輸入電電郵不匹配',
  INVALID_EMAIL: '此電郵地址無效',
  SEND_VERIFICATION_EMAIL: '發送驗證郵件',
  VERIFICATION_EMAIL_SENT: '驗證郵件已發送',
  RESET_PASSWORD: '重置密碼',
  RESET_YOUR_PASSWORD: '重置您的密碼',
  RESET_PASSWORD_FAILED: '重置密碼失敗',
  VERIFY_URL_LINK: '驗證URL鏈接',
  SIGN_OUT: '登出',
  SIGN_OUT_CHECK: '您確定現在登出嗎',
  VALIDATING_USER_CREDENTIAL: '驗證身份信息中',
  BYE: '再會'
}

const menu = {
  DASHBOARD: '儀表板',
  SCHOOL_DASHBOARD: '學校儀表板',
  STUDENT: '學生',
  SCHOOL_ANALYTICS: '校內分析',
  SCHOOL_EXECUTIVES: '校內高管',
  ACADEMIC_EXTERNAL: '學術分析',
  ATTENDANCE: '出勤率分析',
  BEHAVIOR: '學生行為分析',
  LEARNING: '學習',
  WELLBEING: '福利相關',
  SYSTEM_MANAGEMENT: '系統管理',
  GENERAL: '通用',
  USER: '用戶',
  MAINTENANCE: '維護',
  PARENT_MODULE: '家長端模塊',
  PLATFORM_MANAGEMENT: '平台管理',
  DATA: '數據',
  SCHOOLS: '學校',
}

const header = {
  HI: '您好',
  UPDATED_IN: '更新於',
  SETTING: '設置',
  VIEW_NOTIFICATION: '檢查消息',
  NEED_HELP: '尋求幫助',
}

const subjects = {
  ENGLISH: '英語',
  MATH: '數學',
  RELIGIOUS_EDUCATION: '宗教教育',
  HEALTH_PHYSICAL_EDUCATION: '體育',
  SCIENCE: '科學',
  LANGUAGES: '語言',
  RESEARCH_INVESTIGATION: '研究與發現',
  HUMANITIES: '人文學',
  ART: '美術',
  LEARNING_ENHANCEMENT_TEAM: '學習輔導團隊',
  FOOD: '烹飪',
  PATHWAYS: '道路',
  TECHNOLOGIES: '科技'
}

const filter = {
  SEARCH_STUDENT_ID_NAME: '根據ID/姓名搜索學生',
  SEARCH_BY_FILTER: '過濾器搜索',
  SEARCH: '搜索',
  RESULT: '結果',
  YEAR: '年份',
  NOT_SELECTED: '未選擇',
  SEMESTER: '學期',
  HOMEROOM_CODE: '班號',
  DOMAIN: '學科',
  SUBJECT: '課程',
  SUBJECTS: '課程',
  SUBJECT_CLASS: '課程班號',
  ALL: '全部',
  APPLY: '應用',
  UPDATE_STUDENT_LIST: '更新學生列表',
  WHATS_NEW: '新消息',
  SEARCH_STUDENT: '搜索學生',
  SCHOOL_LINKS: "校內鏈接",
  DAILY_MESSAGE: '每日消息',
  TEACHER_ABSENCE: '教師缺席時間',
  STUDENT_LIST: '學生列表',
  CLASS: '班級'
}

const userProfile = {
  MY_PROFILE: '我的賬號信息',
  USER_PROFILE: '用戶檔案',
  RESET_NOW: '重置密碼',
  RESET_PASSWORD_SUCCESS: '密碼重置成功',
  PREVIOUS_PASSWORD: '舊密碼',
  NEW_PASSWORD: '新密碼',
  CONFIRM_NEW_PASSWORD: '確認新密碼',
}

const dashboard = {
  SCHOOL_PROFILE: '校內基礎信息',
  TOTAL_NUM_STUDENTS: '學生總數',
  TOTAL_NUM_TEACHERS: '教師總數',
  TOTAL_NUM_STAFFS: '職工總數',
  TOTAL_NUM_STUDENTS_12: '12年級學生總數',
  TOTAL_NUM_STUDENTS_11: '11年級學生總數',
  TOTAL_NUM_STUDENTS_10: '10年級學生總數',
  TOTAL_NUM_STUDENTS_9: '9年級學生總數',
  TOTAL_NUM_STUDENTS_8: '8年級學生總數',
  TOTAL_NUM_STUDENTS_7: '7年級學生總數',
  SCHOOL_YEARLY_PERFORMANCE: '校內年度表現',
  SCHOOL_NAPLAN_OVERVIEW_BY_YEAR_YEAR_LEVEL: '校內NAPLAN表現概覽（根據年份和年級劃分）',
  IN_YEAR: '按年份',
  FOR_YEAR_LEVEL: '按年級',
  TEACHER_STAFF_MSG: '職工信息',
  IS_MARKED: '已批改',
  SUBMISSION_DATE: '提交日期',
  STUDENT_AMOUNT: '已批改學生數量',
  TASK_DUE_DATE_LIST: '作业截止日期列表',
  TODAY_CALENDAR: '日曆',
  NO_EVENT_TODAY: '無今日活動',
  VIEW_CALENDAR: '查看日曆',
  EVENT_TITLE: '活動標題',
  START_TIME: '開始時間',
  END_TIME: '結束時間',
  LOCATION: '地點',
  PLANNING_NOTES: '規劃說明',
  EVENT_DETAIL: '活動詳情',
  ADD_EVENT: '新增活動',
  EDIT_EVENT: '編輯活動',
  DELETE_EVENT: '刪除活動',
  STUDENT_PERFORMANCE: '學生表現',
  CLASS_PERFORMANCE: '班級表現',
  NO_NUMERICAL_DATA: '無數據',
}

const teacherDashboard = {
  MY_TEACHING: '我的教学表现',
  MY_STUDENT: '我的學生',
  ORDER_BY: '排序方式',
  BY_NAME: '根據姓名',
  MARKING_STATISTICS: '批改統計',
  TASK_NAME: '作業標題',
  AVG_DAY_BTW_MARKING_DATE_SUBMISSION_DATE: '批改與提交日期之間的平均天數',
  DAYS: '天',
}

const studentOverview = {
  STUDENT_OVERVIEW: '學生概覽',
  STUDENT_LIST: '學生列表',
  MENTOR: '導師',
}

const studentDetail = {
  STUDENT_PROFILE: '學生資料',
  OVERVIEW: '概覽',
  AT_SCHOOL: '校內',
  EXTERNAL: '校外',
  SOCIAL_BEHAVIOR: '總體表現',
  ACADEMICS: '學術',
  ENROLMENT: '招生',
  TIMETABLE: '課程表',
  ACCELERATING: '加速班',
  ADJUSTMENT: '調整',
  YEARLY_PERFORMANCE: '年度表現',
  NAPLAN_PERFORMANCE: 'NAPLAN總體表現',
  BEHAVIOR_PERFORMANCE: '行為表現',
  STUDENT_YEARLY_PERFORMANCE_OVERVIEW: '學生年度表現概覽',
  STUDENT_NAPLAN_YEARLY_PERFORMANCE_OVERVIEW: '學生NPLAN表現概覽',
  STUDENT_BEHAVIOR_OVERVIEW: '學生行為表現概覽',
  BY_SCORE: '根據分數',
  BY_CLASS: '根據班級',
  STUDENT_AVG_SCORE: '學生平均分數',
  NO_RESULT: '無數據',
  STUDENT_AVG_MARKS_IN_HISTORGRAM: '學生平均分數（柱狀圖）',
  NO_CLASS_CODE_SELECTED: '班號未選擇',
  STUDENT_AVG_MARKS_IN_BOXPLOT: '學生平均分數（箱型圖）',
  DETAIL: '詳情',
  NUMBER: '數值',
  NO_RESULT_FOUND_FOR: '無數據 -',
  NO_PAT_RESULT_FOUND: '無PAT數據',
  TYPE: '類別',
  COMPLETED_DATE_TIME: '完成日期/時間',
  SCALE: '比例',
  PERCENTILE: '百分位數',
  ACTION: '操作',
  VIEW: '查看',
  STUDENT_SCORE: "學生分數",
  SCHOOL_SCORE: "學校分數",
  QUESTION_NO: '題號',
  PERCENTAGE_CORRECT: '學校平均答對百分比',
  QUESTION_DIFFICULTY: '問題難度',
  CORRECT_ANSWER: '正確答案',
  STUDENT_ANSWER: '學生答案',
  OVERALL_ATTENDANCE_RATE: '整體出勤率',
  HOW_MANY_TIME_LATE_TO_CLASS: '遲到次數',
  MANAGEMENT_OVERVIEW: '管理概覽',
  COMMENDATION: '表揚',
  DETENTION: '留堂',
  INCIDENT: '事故',
  SUSPENSION: '被停課',
  DATE: '日期',
  SOCIAL_INDICATOR_VALUE: '社會指標點數',
  CATEGORY: '類別',
  NOTES: '筆記',
  CLASSCODE: '班號',
  STUDENT_PERFORMANCE_IN_TASK_VS_CLASS_PERFORMANCE: '作業表現（學生對比班級）',
  TASK: '作業',
  TASK_TITLE: '作業題目',
  MARKING_FEEDBACK: '批改反饋',
  SCORE: '分數',
  STUDENT_PERFORMANCE_IN_THIS_TEST: '學生在此測驗中的整體表現',
  NO_RESULT_FOR: '無數據',
  SUBJECT_TASKS_SUBMISSION_STATUS: '科目作業提交情況',
  SUBMISSION_ON_TIME: '按時提交',
  TOTAL_TASK: '全部作業',
  ON_TIME_RATE: '按時提交率',
  NOT_YET_SUBMITTED: '尚未提交',
  OVER_DUE_SUBMISSION: '逾期提交',
  STUDENT_SCHOOL_TASK_STATUS: '學校作業狀態',
  GROWTH_OVERVIEW: '增長率分析',
  TOGGLE_DISPLAY_MODE: '切換展示模式',
  GROWTH: '增長',
  BY_AMOUNT: '根據數量',
  ASSESSMENT_SCORE: '考試分數',
  CLASS_WORK_SCORE: '課題作業分數',
  DERIVED_SCORE: '衍生分數',
  TASKS: '作業數',
  SUBMISSION_RATE: '提交率',
  ON_TIME_SUBMISSION : '按時提交',
  STUDENT_PERFORMANCE_OVERVIEW: '學生總體表現',
  LEGENDS: '說明',
  STRAND: '能力方面',
  STUDENT_RESULT: '學生成績',
  STUDENT_RESULT_BAND: '學生成績評級',
  PAT_NAME: 'PAT名稱',
  STANDARDISED_TEST: '標準化測試',
  STUDENT_YEARLY_LEARNER_ATTRIBUTE: '年度學習態度總結',
  LEARNER_ATTRIBUTE: '學習態度',
  ALWAYS: '始終',
  USUALLY: '經常',
  SOMETIMES: '偶爾',
  ATTENTION_REQUIRED: '需要注意',
  LEVEL_DESCRIPTION: '評級詳情',
}

const schoolExecutives = {
  TASK_SUBMISSION_ANALYSIS: '提交作業分析',
  TASK_PERFORMANCE_ANALYSIS: '作業表現分析',
  FEEDBACK_ANALYSIS: '反饋分析',
  SUBMISSION_ANALYSIS: '提交分析',
  COHORT_ANALYSIS: '隊列分析',
  ORDER_STUDENTS_IN_YEAR_LEVEL: '學生依年級排序',
  ADVANCED_SEARCH: '進階搜索',
  AVG_SUBMISSION: '平均提交',
  AVG_DUE_MARKING_DATE: '平均到期/批改日期',
  AVG_FEEDBACK: '平均反饋',
  TOTAL_WRITTEN_FEEDBACK: '書面反饋總結',
  TOTAL_UNSUBMITTED_TASKS: '未提交作業總結',
  RESULT_BY: '搜索結果',
  ALL_HOME_ROOMS: '所有班號',
  ALL_DOMAINS: '所有學科',
  ALL_SUBJECTS: '所有課程',
  ALL_CLASSES: '所有班級',
  AVG_SUBMISSION_TO_MARKING_DAYS_BY_CLASS_TEACHER: '平均於批改日提交的作業總結（教師對比）',
  AVG_OF_DAYS_BTW_DUE_DATE_AND_MARKING_DATE_BY_CLASS_TEACHER: '平均於截止日和批改日之間提交的作業總結（教師對比）',
  AVG_FEEDBACK_PER_STUDENT_BY_CLASS_TEACHER: '學生平均反饋總結（老師對比）',
  TOTAL_WRITTEN_FEEDBACK_BY_CLASS_TEACHER: '書面反饋總結（教師對比）',
  SUBJECT_WITH_NO_FEEDBACK_YET_BY_CLASS_TEACHER: '當前未獲得反饋的科目總結（教師對比）',
  TASKS_LISTED_SIMON_PAM_AS_UNSUBMITTED: 'Subject tasks unsubmitted after Due dates (sorted by class teacher)',
  NON_SUBMISSION: '尚未提交的作業',
  UNSUBMITTED_TASKS_BY_SUBJECT: '尚未提交的作業總結（課程對比）',
  UNSUBMITTED_TASKS_BY_STUDENT: '尚未提交的作業總結（學生對比）',
  TOTAL_NON_SUBMISSION_REASON: '未提交的作業原因總結',
  RESULT_FOR: '搜索結果',
  NON_SUBMISSION_BY_CLASS_TEACHER: '尚未提交的作業總結（教師對比）',
  TASKS_LISTED_SIMON_PAM_AS_UNSUBMITTED_BY_SUBJECT_DOMAIN: 'Subject tasks unsubmitted after Due dates (sorted by class teacher)',
  NAME: '名稱',
  REASON: '原因',
  DUE_DATE: '截止日',
  COUNT_NON_SUBMISSION_REASON_BY_STUDENT: '未提交原因總計（學生對比）',
  PERFORMANCE_OVERVIEW: '表現概覽',
  MARKS_ACROSS_DOMAIN: '學科分數一覽',
  STUDENT_PERFORMANCE_OVERVIEW_BY_DENSITY: '學生表現概覽（密度對比）',
  MARKS_ACROSS_DOMAIN_IF_DOMAIN_SELECTED: '學科分數一覽（如學科已選擇）',
  STUDENT_MARKS_ACROSS_DOMAINS_BY_CLASS_CODE: '學科分數一覽（班號對比）',
  STUDENT_MARKS_ACROSS_DOMAINS_BY_YEAR_LEVEL: '學科分數一覽（年級對比）',
  STUDENT_MARKS_ACROSS_DOMAINS_BY_DOMAIN: '學科分數一覽（學科對比）',
  AVG_SCORE_GRAPH: '學生均分圖表展示',
  AVG_SCORE_TABLE: '學生均分列表展示',
  AVG_SCORE_AND_FIRST_TASK_TITLE_BY_NAME: '學生均分（學生名字對比）',
  AVG_SCORE: '平均分',
  CHART_AREA: '圖表區域',
  TOP_10_STUDENTS_IN: '前十學生 —— ',
  ROOM_CODE: '班號',
  HIGH_SCORE: '最高分',
  LOW_SCORE: '最低分',
  MEDIUM_SCORE: '中分',
  TOTAL_STUDENTS: '所有學生',
  MARK_DISTRIBUTION: '分數分佈',
}

const academicExternal = {
  SCHOOL_PERFORMANCE_IN_THIS_TEST: '學校在此測試中的整體表現',
}

const attendance = {
  ATTENDANCE_RATE: '出勤率',
  BY_WEEK: '以每周作對比',
  BY_MONTH: '以每月作對比',
  SHOW_ONLY_FIRST_TWO_SUBJECTS: '僅展示前兩個科目',
}

const behavior = {
  SUMMARY: '總結',
}

const general = {
  USER_ROLE: '權限用戶',
  PERMISSION_STRATEGY: '權限對策',
  USER_ROLE_NAME: '權限用戶名稱',
  ADD_ROLE: '增加權限用戶',
  SELECT_EXISTING_USER_ROLE: '選擇已有的權限用戶',
  CREATE_STRATEGY_FOR: '創建權限給',
  PERMISSION_STRATEGY_NAME: '權限對策名稱',
  ADD_STRATEGY: '增加權限',
  SCHOOL_INFORMATION_MANAGEMENT: '學校信息管理',
  EMAIL_SERVICE: '電郵服務',
  PERMISSION: '權限',
  GENERAL_SETTING: '通用設定',
  SCHOOL_NAME: '學校名稱',
  UPLOAD: '上傳',
  CURRENT_SCHOOL_LOGO: '當前學校LOGO',
  PREVIEW: '預覽',
  REMOVE: '移除',
  SAVE: '保存',
  EMAIL_SERVICE_SETTING: '電郵服務設置',
  COLOR_BOARD: '主題色',
  EMAIL_ACCOUNT: '電郵賬戶',
  EMAIL_PASSWORD: '電郵密碼',
  PERMISSION_SETTING: '權限設定',
  NO_STRATEGY_YET: '無相應權限對策',
  PREVIEW_PERMISSION: '預覽權限',
  CREATE_NEW: '創建',
  CURRENT_USER: '當前用戶',
  NO_LEVEL: '無階級',
  EDIT_CURRENT_PERMISSION_LEVEL: '編輯當前權限階級',
  RENAME_LEVEL: '階級重命名',
  DELETE_LEVEL: '刪除階級',
  NEW_LEVEL_NAME: '新階級名稱',
  DELETE_LEVEL_CHECK: '您確定刪除此階級嗎',
  LOGO_UPLOADED: 'LOGO已上傳',
  SCHOOL_INFO_UPDATED: '學校信息已更新',
  PREPARING_DATA_NOTIFY: '數據籌備中... 處理過程將需要一小時左右，請耐心等待結果',
  SYNC: '同步',
  TABLE: '數據庫表',
}

const user = {
  USER_LIST: '用戶列表',
  UPDATE_USER: '編輯用戶信息',
  CREATE_USER: '創建新用戶',
  RESULTS: '條結果',
  CHECK_SIMON: '驗證Simon數據',
  MOBILE: '手機',
  PERMISSION_LEVEL: '權限階級',
  NO_PERMISSION_FOUND: '此學校還未創建任何權限對策',
  RESET_ALL: '重置',
  USER_PERMISSION_SETTING: '用戶權限設定',
  DELETE: '刪除',
  SEARCH_USER: '搜索用戶',
  DELETE_USER_CHECK: '你確定刪除此用戶嗎',
}

const maintenance = {
  SYNC_DATA: '同步數據',
  PREPARE_ATTENDANCE_STATUS_DATA: '處理出勤率狀態數據',
  STUDENT_TOKEN_DATA: '學生令牌數據',
  SYNC_STATUS_LIST: '同步狀態列表',
  TABLE_NAME: '數據庫表',
  RECORDS: '數據記錄',
  SYNC_DATE_TIME: '同步日期/時間',
  SYNC_DATA_CHECK: '確定開始數據同步',
  SYNC_DATA_WARNING: '警告：同步數據將需要2小時左右完成同步操作',
  SYNC_NOW: '開始同步',
  ATTENDANCE_STATUS_LIST: '出勤率狀態列表',
  ACADEMIC_YEAR: '學年',
  PREPARE_STATUS: '處理狀態',
  PREPARE_ATTENDANCE_DATA: '處理出勤率數據',
  PREPARE_NEW_ATTENDANCE_DATA: '處理新出勤率數據',
  PLS_PICK_SEMESTER_FIRST: '請必須在處理出勤率數據前先選擇好目標學期',
  UPDATE: '開始更新',
  CONTINUE: '繼續',
  CURRENT_STUDENT: '當前學生',
  REMOVE_ALL: '移除所有',
  SELECT_ALL: '勾選所有',
  CONFIRM: '確認',
  CLEAR_FILTER: '清除過濾',
  GENERATE_TOKEN: '生成令牌',
  SELECTED_STUDENT: '已選學生',
  PREPARE_DATA: '準備數據',
  ALL_STUDENTS_SELECTED: '已勾選所有學生',
  STUDENTS_SELECTED: '名學生已勾選',
  NO_STUDENTS_SELECTED_YET: '目前沒有勾選學生',
  THE_SELECTED: '已選擇的',
  ALREADY_EXISTS_IN_STATUS_LIST: '數據已存在於列表裡，是否選擇更新此數據',
  PREPARE_ATTENDANCE_DATA_CHECK: '您確定處理出勤率數據 —— ',
}

const parentModule = {
  MODULE: '模塊',
  MODULE_NAME: '模塊名稱',
  DISPLAY_STATUS: '顯示狀態',
  TOGGLE_DISPLAY: '切換顯示狀態',
  TO_VISIBLE: '顯示',
  TO_HIDDEN: '隱藏',
  DISPLAY_STATUS_UPDATED: '顯示狀態已更新',
}

const advancedSearch = {
  PLS_GIVE_VALUES_TO_ABOVE_DROPDOWN: '班號選擇將基於以上下拉菜單選項',
}

export default {
  translation: {
    ...login,
    ...menu,
    ...header,
    ...filter,
    ...subjects,
    ...userProfile,
    ...dashboard,
    ...teacherDashboard,
    ...studentOverview,
    ...studentDetail,
    ...schoolExecutives,
    ...academicExternal,
    ...attendance,
    ...behavior,
    ...general,
    ...user,
    ...maintenance,
    ...parentModule,
    ...advancedSearch,
    lang: 'zh-TW',
    GO_BACK: '返回',
    WELCOME: '您好',
    CANCEL: '取消',
    PLEASE_CONTACT_ADMIN: '請聯繫系統管理員',
    SHOW_ALL_SUBJECTS: '顯示所有科目',
    SHOW_ONLY_ENGLISH_MATH: '僅顯示英語/數學',
    YEAR_LEVEL: '年級',
    TEACHER: '教師',
    FIRST_NAME: '名',
    LAST_NAME: '姓',
    SURNAME: '姓',
    STUDENT: '學生',
    ERROR: '失敗',
    PLEASE_FILL_IN_ALL_FIELDS: '請確保所有空格處已填寫',
    NO: '否',
    YES: '是',
    TODAY: '今天',
    PREV: '往前',
    NEXT: '往後',
    ABSENT_BETWEEN: '缺席於',
    CURRENT: '當前',
    NO_STUDENTS_FOUND: '無法找到相應學生',
    COMPARISION_OF_STUDENT_YEARLY_LEARNER_ATTRIBUTE: '年度學習態度總結對比',
    AUTHENTICATION_EXPIRED: '登入狀態超時',
  }
};
