const login = {
  SIGN_IN: 'Sign In',
  SIGN_IN_NOW: 'Sign In Now',
  WELCOME_TO_THE_SYSTEM: 'Welcome to The System',
  FORGOT_PASSWORD: 'Forgot Password',
  USERNAME: 'Username',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Your Password',
  TWO_PASSWORDS_NOT_MATCH: 'Passwords Do Not Match',
  INCORRECT_USERNAME_PASSWORD: 'Incorrect Username/Password',
  EMAIL: 'Email',
  CONFIRM_EMAIL: 'Confirm Email',
  TWO_EMAILS_NOT_MATCH: 'Emails Do Not Match',
  INVALID_EMAIL: 'Invalid Email',
  SEND_VERIFICATION_EMAIL: 'Send Verification Email',
  VERIFICATION_EMAIL_SENT: 'Verification Email Sent',
  RESET_PASSWORD: 'Reset Password',
  RESET_YOUR_PASSWORD: 'Reset Your Password',
  RESET_PASSWORD_FAILED: 'Reset Password Failed',
  VERIFY_URL_LINK: 'Verify URL Link',
  SIGN_OUT: 'Sign Out',
  SIGN_OUT_CHECK: 'Are you wish to you sign out now',
  VALIDATING_USER_CREDENTIAL: 'Validating User Credentials',
  BYE: 'Bye',
}

const menu = {
  DASHBOARD: 'Teacher Dashboard',
  SCHOOL_DASHBOARD: 'School Dashboard',
  STUDENT: 'Student',
  SCHOOL_ANALYTICS: 'School Analytics',
  SCHOOL_EXECUTIVES: 'School Executives',
  ACADEMIC_EXTERNAL: 'Academic',
  ATTENDANCE: 'Attendance',
  BEHAVIOR: 'Behavior',
  LEARNING: 'Learning',
  WELLBEING: 'Wellbeing',
  SYSTEM_MANAGEMENT: 'System Management',
  GENERAL: 'General',
  USER: 'User',
  MAINTENANCE: 'Maintenance',
  PARENT_MODULE: 'Parent Module',
  PLATFORM_MANAGEMENT: 'Platform Management',
  DATA: 'Data',
  SCHOOLS: 'Schools',
}

const header = {
  HI: 'Hi',
  UPDATED_IN: 'Updated in',
  SETTING: 'Setting',
  VIEW_NOTIFICATION: 'View Notifications',
  NEED_HELP: 'Need Helps',
}

const subjects = {
  ENGLISH: 'English',
  MATH: 'Mathematics',
  RELIGIOUS_EDUCATION: 'Religious Education',
  HEALTH_PHYSICAL_EDUCATION: 'Heath/Physical Education',
  SCIENCE: 'Science',
  LANGUAGES: 'Languages',
  RESEARCH_INVESTIGATION: 'Research Investigation',
  HUMANITIES: 'Humanities',
  ART: 'The Arts',
  LEARNING_ENHANCEMENT_TEAM: 'Learning Enhancement Team',
  FOOD: 'Food',
  PATHWAYS: 'Pathways',
  TECHNOLOGIES: 'Technologies',
}

const filter = {
  SEARCH_STUDENT_ID_NAME: 'Search Student By ID/Name',
  SEARCH_BY_FILTER: 'Search By Filter',
  SEARCH: 'Search',
  RESULT: 'Result',
  YEAR: 'Year',
  NOT_SELECTED: 'Not Selected',
  SEMESTER: 'Semester',
  HOMEROOM_CODE: 'Home Room Code',
  DOMAIN: 'Domain',
  SUBJECT: 'Subject',
  SUBJECTS: 'Subjects',
  SUBJECT_CLASS: 'Sub. Class',
  ALL: 'All',
  APPLY: 'Apply',
  UPDATE_STUDENT_LIST: 'Update Student List',
  SCHOOL_LINKS: "School Links",
  SEARCH_STUDENT: 'Search Student',
  TEACHER_ABSENCE: 'Teacher Absence',
  STUDENT_LIST: 'Student List',
  CLASS: 'Class',
}

const userProfile = {
  MY_PROFILE: 'My Profile',
  USER_PROFILE: 'User Profile',
  RESET_NOW: 'Reset Now',
  RESET_PASSWORD_SUCCESS: 'Reset Password Success',
  PREVIOUS_PASSWORD: 'Previous Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_NEW_PASSWORD: 'Confirm New Password',
}

const dashboard = {
  SCHOOL_PROFILE: 'School Profile',
  TOTAL_NUM_STUDENTS: 'Total Number of Students',
  TOTAL_NUM_TEACHERS: 'Total Number of Teachers',
  TOTAL_NUM_STAFFS: 'Total Number of Staff',
  TOTAL_NUM_STUDENTS_12: 'Number of Students in Year 12',
  TOTAL_NUM_STUDENTS_11: 'Number of Students in Year 11',
  TOTAL_NUM_STUDENTS_10: 'Number of Students in Year 10',
  TOTAL_NUM_STUDENTS_9: 'Number of Students in Year 9',
  TOTAL_NUM_STUDENTS_8: 'Number of Students in Year 8',
  TOTAL_NUM_STUDENTS_7: 'Number of Students in Year 7',
  SCHOOL_YEARLY_PERFORMANCE: 'School Yearly Performance',
  SCHOOL_NAPLAN_OVERVIEW_BY_YEAR_YEAR_LEVEL: 'School Naplan Overview By Year/Year Level',
  IN_YEAR: 'In Year',
  FOR_YEAR_LEVEL: 'For Year Level',
  DAILY_MESSAGE: 'Daily Message',
  TEACHER_STAFF_MSG: 'Teacher Staff Message',
  IS_MARKED: 'Is Marked',
  SUBMISSION_DATE: 'Submission Date',
  STUDENT_AMOUNT: 'Marked Student Amount',
  TASK_DUE_DATE_LIST: 'Task Due Date List',
  TODAY_CALENDAR: 'Today Calendar',
  NO_EVENT_TODAY: 'No Event Today',
  VIEW_CALENDAR: 'View Calendar',
  EVENT_TITLE: 'Event Title',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  LOCATION: 'Location',
  PLANNING_NOTES: 'Planning Notes',
  EVENT_DETAIL: 'Event Detail',
  ADD_EVENT: 'Add Event',
  EDIT_EVENT: 'Edit Event',
  DELETE_EVENT: 'Delete Event',
  STUDENT_PERFORMANCE: 'Student Performance',
  CLASS_PERFORMANCE: 'Class Performance',
  NO_NUMERICAL_DATA: 'No Numerical Data',
}

const teacherDashboard = {
  MY_TEACHING: 'My Teaching',
  MY_STUDENT: 'My Student',
  ORDER_BY: 'Order By',
  BY_NAME: 'By Name',
  MARKING_STATISTICS: 'Marking Statistics',
  TASK_NAME: 'Task Name',
  AVG_DAY_BTW_MARKING_DATE_SUBMISSION_DATE: 'Average Days Between Marking & Submission Date',
  DAYS: 'Days',
}

const studentOverview = {
  STUDENT_OVERVIEW: 'Student Overview',
  STUDENT_LIST: 'Student List',
  MENTOR: 'Mentor',
}

const studentDetail = {
  STUDENT_PROFILE: 'Student Profile',
  OVERVIEW: 'Overview',
  AT_SCHOOL: 'At School',
  EXTERNAL: 'External',
  SOCIAL_BEHAVIOR: 'Social Behavior',
  ACADEMICS: 'Academics',
  ENROLMENT: 'Enrolment',
  TIMETABLE: 'Timetable',
  ACCELERATING: 'Accelerating',
  ADJUSTMENT: 'Adjustment',
  YEARLY_PERFORMANCE: 'Yearly Performance',
  NAPLAN_PERFORMANCE: 'NAPLAN Performance',
  BEHAVIOR_PERFORMANCE: 'Behavior Performance',
  STUDENT_YEARLY_PERFORMANCE_OVERVIEW: 'Student Yearly Performance Overview',
  STUDENT_NAPLAN_YEARLY_PERFORMANCE_OVERVIEW: 'Student NAPLAN Yearly Performance Overview',
  STUDENT_BEHAVIOR_OVERVIEW: 'Student Behavior Overview',
  BY_SCORE: 'By Score',
  BY_CLASS: 'By Class',
  STUDENT_AVG_SCORE: 'Student Average Score',
  NO_RESULT: 'No Result',
  STUDENT_AVG_MARKS_IN_HISTORGRAM: 'Student Average Marks in Historgram',
  NO_CLASS_CODE_SELECTED: 'No Class Code Selected',
  STUDENT_AVG_MARKS_IN_BOXPLOT: 'Student Average Marks in Boxplot',
  DETAIL: 'Detail',
  NUMBER: 'Number',
  NO_RESULT_FOUND_FOR: 'No Result Found For',
  NO_PAT_RESULT_FOUND: 'No PAT Result Found',
  TYPE: 'Type',
  COMPLETED_DATE_TIME: 'Completed Date/Time',
  SCALE: 'Scale',
  PERCENTILE: 'Percentile',
  ACTION: 'Action',
  VIEW: 'View',
  STUDENT_SCORE: "Student Score",
  SCHOOL_SCORE: "School Score",
  QUESTION_NO: 'Question No',
  PERCENTAGE_CORRECT: 'School Average % Correct',
  QUESTION_DIFFICULTY: 'Question Difficulty',
  CORRECT_ANSWER: 'Correct Answer',
  STUDENT_ANSWER: 'Student Answer',
  OVERALL_ATTENDANCE_RATE: 'Overall Attendance Rate',
  HOW_MANY_TIME_LATE_TO_CLASS: 'How Many Time Late to Class',
  MANAGEMENT_OVERVIEW: 'Management Overview',
  COMMENDATION: 'Commendation',
  DETENTION: 'Detention',
  INCIDENT: 'Incident',
  SUSPENSION: 'Suspension',
  DATE: 'Date',
  SOCIAL_INDICATOR_VALUE: 'Social Indicator Value',
  CATEGORY: 'Category',
  NOTES: 'Notes',
  CLASSCODE: 'Class Code',
  STUDENT_PERFORMANCE_IN_TASK_VS_CLASS_PERFORMANCE: 'Student Performance in Task VS Class Performance',
  TASK: 'Task',
  TASKS: 'Tasks',
  TASK_TITLE: 'Task Title',
  MARKING_FEEDBACK: 'Marking Feedback',
  SCORE: 'Score',
  STUDENT_PERFORMANCE_IN_THIS_TEST: 'Student Performance in strand',
  NO_RESULT_FOR: 'No Result for',
  SUBJECT_TASKS_SUBMISSION_STATUS: 'Subject Tasks Submission Status',
  SUBMISSION_ON_TIME: 'Submission On Time',
  TOTAL_TASK: 'Total Task',
  ON_TIME_RATE: 'On Time Rate',
  NOT_YET_SUBMITTED: 'No Yet Submitted',
  OVER_DUE_SUBMISSION: 'Over Due Submission',
  STUDENT_SCHOOL_TASK_STATUS: 'Student School Task Status',
  GROWTH_OVERVIEW: 'Growth Overview',
  TOGGLE_DISPLAY_MODE: 'Toggle Display Mode',
  GROWTH: 'Growth',
  BY_AMOUNT: 'By Amount',
  ASSESSMENT_SCORE: 'Assessment Score',
  CLASS_WORK_SCORE: 'Class Work Score',
  DERVIED_SCORE: 'Derived Score',
  SUBMISSION_RATE: 'Submission Rate',
  ON_TIME_SUBMISSION: 'On Time Submission',
  STUDENT_PERFORMANCE_OVERVIEW: 'Student Performance Overview',
  LEGENDS: 'Legends',
  STRAND: 'Strand',
  STUDENT_RESULT: 'Student Result',
  STUDENT_RESULT_BAND: 'Student Result Band',
  PAT_NAME: 'PAT Name',
  STANDARDISED_TEST: 'Standardised Test',
  STUDENT_YEARLY_LEARNER_ATTRIBUTE: 'Student Yearly Learner Attribute',
  LEARNER_ATTRIBUTE: 'Learner Attribute',
  ALWAYS: 'Always',
  USUALLY: 'Usually',
  SOMETIMES: 'Sometimes',
  ATTENTION_REQUIRED: 'Attention Required',
  LEVEL_DESCRIPTION: 'Level Description',
}

const schoolExecutives = {
  TASK_SUBMISSION_ANALYSIS: 'Task Submission Analysis',
  TASK_PERFORMANCE_ANALYSIS: 'Task Performance Analysis',
  FEEDBACK_ANALYSIS: 'Feedback Analysis',
  SUBMISSION_ANALYSIS: 'Submission Analysis',
  COHORT_ANALYSIS: 'Cohort Analysis',
  ORDER_STUDENTS_IN_YEAR_LEVEL: 'Order of Students in Year Level',
  ADVANCED_SEARCH: 'Advanced Search',
  AVG_SUBMISSION: 'Average Submission',
  AVG_DUE_MARKING_DATE: 'Average Due/Marking Date',
  AVG_FEEDBACK: 'Average Feedback',
  TOTAL_WRITTEN_FEEDBACK: 'Total Written Feedback',
  TOTAL_UNSUBMITTED_TASKS: 'Total Unsubmitted Tasks',
  RESULT_BY: 'Result By',
  ALL_HOME_ROOMS: 'All Home Rooms',
  ALL_DOMAINS: 'All Domains',
  ALL_SUBJECTS: 'All Subjects',
  ALL_CLASSES: 'All Classes',
  AVG_SUBMISSION_TO_MARKING_DAYS_BY_CLASS_TEACHER: 'Average Submission to Marking Days by Class Teacher',
  AVG_OF_DAYS_BTW_DUE_DATE_AND_MARKING_DATE_BY_CLASS_TEACHER: 'Average of Days Between Due Date and Marking Date by Class Teacher',
  AVG_FEEDBACK_PER_STUDENT_BY_CLASS_TEACHER: 'Average Feedback Per Student by Class Teacher',
  TOTAL_WRITTEN_FEEDBACK_BY_CLASS_TEACHER: 'Total Written Feedback by Class Teacher',
  SUBJECT_WITH_NO_FEEDBACK_YET_BY_CLASS_TEACHER: 'Subject with No Feedback yet by Class Teacher',
  TASKS_LISTED_SIMON_PAM_AS_UNSUBMITTED: 'Tasks listed in Simon/PAM as unsubmitted with due dates in Selected Range by Class Teacher',
  NON_SUBMISSION: 'Non Submission',
  UNSUBMITTED_TASKS_BY_SUBJECT: 'Unsubmitted Tasks by Subject',
  UNSUBMITTED_TASKS_BY_STUDENT: 'Unsubmitted Tasks by Student',
  TOTAL_NON_SUBMISSION_REASON: 'Total Non-Submission Reason',
  RESULT_FOR: 'Result For',
  NON_SUBMISSION_BY_CLASS_TEACHER: 'Non-Submission by Class Teacher',
  TASKS_LISTED_SIMON_PAM_AS_UNSUBMITTED_BY_SUBJECT_DOMAIN: 'Tasks Listed in Simon/Pam as unsubmitted with Due dates in selected range by subjects/domain (group by class teacher)',
  NAME: 'Name',
  REASON: 'Reason',
  DUE_DATE: 'Due Date',
  COUNT_NON_SUBMISSION_REASON_BY_STUDENT: 'Count of Non-Submission Reason by Student',
  PERFORMANCE_OVERVIEW: 'Performance Overview',
  MARKS_ACROSS_DOMAIN: 'Marks Across Domain',
  STUDENT_PERFORMANCE_OVERVIEW_BY_DENSITY: 'Student Performance Overview (by Density)',
  MARKS_ACROSS_DOMAIN_IF_DOMAIN_SELECTED: 'Marks Across Domain (if Domain is Selected)',
  STUDENT_MARKS_ACROSS_DOMAINS_BY_CLASS_CODE: 'Student Marks Across Domains by Class Code',
  STUDENT_MARKS_ACROSS_DOMAINS_BY_YEAR_LEVEL: 'Student Marks Across Domains by Year Level',
  STUDENT_MARKS_ACROSS_DOMAINS_BY_DOMAIN: 'Student Marks Across Domains by Domain',
  AVG_SCORE_GRAPH: 'Average Score Graph',
  AVG_SCORE_TABLE: 'Average Score Table',
  AVG_SCORE_AND_FIRST_TASK_TITLE_BY_NAME: 'Average Score and First Task Title by Name',
  AVG_SCORE: 'Average Score',
  CHART_AREA: 'Chart Area',
  TOP_10_STUDENTS_IN: 'Top 10 Students in ',
  ROOM_CODE: 'Room Code',
  HIGH_SCORE: 'Highest Score',
  LOW_SCORE: 'Lowest Score',
  MEDIUM_SCORE: 'Medium Score',
  TOTAL_STUDENTS: 'Total Students',
  MARK_DISTRIBUTION: 'Mark Distribution',
  STUDENT_TASK_ADHERENCE: 'Student Task Adherence',
  CORHORT_GROWTH: 'Cohort Growth',
  MOST_IMPROVED: 'Most Improved',
  HIGH_ACHIEVERS: 'High Achievers',
  LOW_ACHIEVEMENT_LOW_GROWTH: 'Low Achievement/Low Growth',
  HIGH_ACHIEVEMENT_LOW_GROWTH: 'High Achievement/Low Growth',
  COHORT_GROWTH_VS_PERFORMANCE: 'Cohort Growth VS Performance'
}

const academicExternal = {
  SCHOOL_PERFORMANCE_IN_THIS_TEST: 'School Performance in this Test',
}

const attendance = {
  ATTENDANCE_RATE: 'Attendance Rate',
  BY_WEEK: 'By Week',
  BY_MONTH: 'By Month',
  SHOW_ONLY_FIRST_TWO_SUBJECTS: 'Show Only First Two Subjects',
}

const behavior = {
  SUMMARY: 'Summary',
}


const general = {
  USER_ROLE: 'User Role',
  PERMISSION_STRATEGY: 'Permission Strategy',
  USER_ROLE_NAME: 'User Role Name',
  ADD_ROLE: 'Add Role',
  SELECT_EXISTING_USER_ROLE: 'Select Existing User Role',
  CREATE_STRATEGY_FOR: 'Crate Strategy for ',
  PERMISSION_STRATEGY_NAME: 'Permission Strategy Name',
  ADD_STRATEGY: 'Add Strategy',
  SCHOOL_INFORMATION_MANAGEMENT: 'School Information Management',
  EMAIL_SERVICE: 'Email Service',
  PERMISSION: 'Permission',
  GENERAL_SETTING: 'General Setting',
  SCHOOL_NAME: 'School Name',
  UPLOAD: 'Upload',
  CURRENT_SCHOOL_LOGO: 'Current School Logo',
  PREVIEW: 'Preview',
  REMOVE: 'Remove',
  SAVE: 'Save',
  EMAIL_SERVICE_SETTING: 'Email Service Setting',
  COLOR_BOARD: 'Color Board',
  EMAIL_ACCOUNT: 'Email Account',
  EMAIL_PASSWORD: 'Email Password',
  PERMISSION_SETTING: 'Permission Setting',
  NO_STRATEGY_YET: 'No Strategy Yet',
  PREVIEW_PERMISSION: 'Preview Permission',
  CREATE_NEW: 'Create New',
  CURRENT_USER: 'Current User',
  NO_LEVEL: 'No Level',
  EDIT_CURRENT_PERMISSION_LEVEL: 'Edit Current Permission Level',
  RENAME_LEVEL: 'Rename Level',
  DELETE_LEVEL: 'Delete Level',
  NEW_LEVEL_NAME: 'New Level Name',
  DELETE_LEVEL_CHECK: 'You sure you want to delete this level',
  LOGO_UPLOADED: 'LOGO Uploaded',
  SCHOOL_INFO_UPDATED: 'School Information Updated'
}

const user = {
  USER_LIST: 'User List',
  UPDATE_USER: 'Update User',
  CREATE_USER: 'Create User',
  RESULTS: ' Results',
  CHECK_SIMON: 'Check Simon',
  MOBILE: 'Mobile',
  PERMISSION_LEVEL: 'Permission Level',
  NO_PERMISSION_FOUND: 'No Permission Found in this School',
  RESET_ALL: 'Reset All',
  USER_PERMISSION_SETTING: 'User Permission Setting',
  DELETE: 'Delete Now',
  SEARCH_USER: 'Search User',
  DELETE_USER_CHECK: 'Do you wish to delete this user',
}

const maintenance = {
  SYNC_DATA: 'Sync Data',
  PREPARE_ATTENDANCE_STATUS_DATA: 'Prepare Attendance Status Data',
  STUDENT_TOKEN_DATA: 'Student Token Data',
  SYNC_STATUS_LIST: 'Sync Status List',
  TABLE_NAME: 'Table Name',
  RECORDS: 'Records',
  SYNC_DATE_TIME: 'Sync Data/Time',
  SYNC_DATA_CHECK: 'You wish to sync data now',
  SYNC_DATA_WARNING: 'Warning: This process may take 2 hours to complete.',
  SYNC_NOW: 'Sync Now',
  ATTENDANCE_STATUS_LIST: 'Attendance Status List',
  ACADEMIC_YEAR: 'Academic Year',
  PREPARE_STATUS: 'Prepare Status',
  PREPARE_ATTENDANCE_DATA: 'Prepare Attendance Data',
  PREPARE_NEW_ATTENDANCE_DATA: 'Prepare New Attendance Data',
  PLS_PICK_SEMESTER_FIRST: 'Please pick a semester before preparing the data otherwise you can prepare data for all semesters',
  UPDATE: 'Update Now',
  CONTINUE: 'Continue',
  CURRENT_STUDENT: 'Current Student',
  REMOVE_ALL: 'Remove All',
  SELECT_ALL: 'Select All',
  CONFIRM: 'Confirm',
  CLEAR_FILTER: 'Clear Filter',
  GENERATE_TOKEN: 'Generate Token',
  SELECTED_STUDENT: 'Selected Student',
  PREPARE_DATA: 'Prepare Data',
  ALL_STUDENTS_SELECTED: 'All Students Selected',
  STUDENTS_SELECTED: ' Students Selected',
  NO_STUDENTS_SELECTED_YET: 'No Students Selected Yet',
  THE_SELECTED: 'The selected ',
  ALREADY_EXISTS_IN_STATUS_LIST: ' already exists in the status list, are you wish to update the data',
  PREPARE_ATTENDANCE_DATA_CHECK: 'You wish to prepare the attendance data for ',
  PREPARING_DATA_NOTIFY: 'Preparing data... This process takes about 10 MINS, please come back later for the result',
  SYNC: 'Sync',
  TABLE: 'Table',
}

const parentModule = {
  MODULE: 'Module',
  MODULE_NAME: 'Module Name',
  DISPLAY_STATUS: 'Display Status',
  TOGGLE_DISPLAY: 'Toggle Display',
  TO_VISIBLE: 'To Visible',
  TO_HIDDEN: 'To Hidden',
  DISPLAY_STATUS_UPDATED: 'Display Status Updated'
}

const advancedSearch = {
  PLS_GIVE_VALUES_TO_ABOVE_DROPDOWN: 'Please give values to above dropdowns before selecting the class code',
}

export default {
  translation: {
    ...login,
    ...menu,
    ...header,
    ...filter,
    ...subjects,
    ...userProfile,
    ...dashboard,
    ...studentOverview,
    ...studentDetail,
    ...schoolExecutives,
    ...academicExternal,
    ...attendance,
    ...behavior,
    ...general,
    ...user,
    ...maintenance,
    ...parentModule,
    ...advancedSearch,
    ...teacherDashboard,
    lang: 'en-AU',
    GO_BACK: 'Go Back',
    WELCOME: 'Welcome',
    CANCEL: 'Cancel',
    PLEASE_CONTACT_ADMIN: 'Please Contact Admin',
    SHOW_ALL_SUBJECTS: 'Show All Subjects',
    SHOW_ONLY_ENGLISH_MATH: 'Show Only English & Maths',
    YEAR_LEVEL: 'Year Level',
    TEACHER: 'Teacher',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    SURNAME: 'Surname',
    STUDENT: 'Student',
    ERROR: 'Error',
    PLEASE_FILL_IN_ALL_FIELDS: 'Please Fill in All Fields',
    NO: 'No',
    YES: 'Yes',
    TODAY: 'Today',
    PREV: 'Prev',
    NEXT: 'Next',
    ABSENT_BETWEEN: 'Absent Between',
    CURRENT: 'Current',
    NO_STUDENTS_FOUND: 'No Students Found',
    COMPARISION_OF_STUDENT_YEARLY_LEARNER_ATTRIBUTE: 'Comparision of Student Yearly Learner Attribute',
    AUTHENTICATION_EXPIRED: 'Authentication Expired',
    ADD_NEW_EVENT_SUCCESS: 'Add New Event Success',
    ADD_NEW_EVENT_ERROR: 'Add New Event Failed',
    EDIT_EVENT_SUCCESS: 'Edit Event Success',
    EDIT_EVENT_ERROR: 'Edit Event Failed',
    DELETE_EVENT_SUCCESS: 'Delete Event Success',
    DELETE_EVENT_ERROR: 'Delete Event Failed',
    NO_TEACHER_ABSENT: 'No Teacher Absent',
    NO_RESULT_FOUND: 'No Result Found',
    NO_MSG_FOUND: 'No Message Found',
    DELETE_EVENT_CONFIRM: 'Delete Event Confirm',
    NO_PAT_READING_RESULT: 'No PAT Reading Test Result',
    PERMISSION_FOR_USER: 'Permission for User',
    APPLY_TEMPLATE: 'View Template',
    GO_TEMPLATE_SETTING: 'Go Template Setting',
    CURRENT_PERMISSION: 'Current Permission',
    BACK_USER_PERMISSION: 'Back to User Permission',
    TEMPLATE: 'Template',
    CLEAR_ALL: 'Untick All',
    SAVE_PERMISSION: 'Save Permission',
    NO_USER_ROLE_OR_LEVEL_SELECTED: 'No User Role or Level is Selected',
    SCHOOL_ATTENDANCE_LIST: 'School Attendance List',
    PREPARE_ALL_SEMESTER_ATTENDANCE_DATA_CHECK: 'You wish to prepare the attendance data for all semesters?',
    PREPARING_ALL_SEMESTER_DATA_NOTIFY: 'Preparing data... This process takes about AN HOUR, please come back later for the result',
    STUDENT_PERFORMANCE_LIST: 'Student Performance List',
    UPDATED_TIME: 'Updated Time',
    PREPARE_STUDENT_PERFORMANCE_DATA: 'Prepare Student Performance Data',
    PREPARE_ALL_SEMESTER_STUDENT_PERFORMANCE_DATA_CHECK: 'You wish to prepare the student performance data for all semesters?',
    PREPARE_STUDENT_PERFORMANCE_DATA_CHECK: 'You wish to prepare the student performance data for ',
    LEARNING_QUERY_RESULT_LIST: 'Learning Query Result List',
    UPDATE_LEARNING_QUERY_DATA: 'Update Learning Query Data',
    PREPARE_ALL_SEMESTER_LEARNING_QUERY_DATA_CHECK: 'You wish to prepare the learning query data for all semesters?',
    PREPARE_LEARNING_QUERY_DATA_CHECK: 'You wish to prepare the learning query data for ',
    SCHOOL_ATTENDANCE_DATA: 'School Attendance Data',
    STUDENT_PERFORMANCE_DATA: 'Student Performance Data',
    LEARNING_QUERY_RESULT_DATA: 'Learning Query Result Data',
    PREPARE_DATA_ALL_SEMESTERS: 'Prepare Data (for All Semesters)',
    TASK_PERFORMANCE: 'Task Performance',
  }
};



