/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import { parseValidationError } from '../utilities/parse-validation-error';
import { composeImageUrlsInCartItems, ImageSize } from '../utilities/image-url-composer';
import { isNative, isWeb } from '../utilities/platform';
import atob from '../utilities/atob';
import RootStore from './root-store';
import { backEndBaseURL } from '../constants/settings';
import { THEME_NAME_CONVERSION } from '../constants/style';


export default class CollegeStore {

  collegeInfo: any | null = null;

  collegeList: any = { data: [], total: 0 };

  collegeBasicList = [];

  pmsTemplate: any | null = [];

  permissionList: any = [];

  permissionByLevelsList: any | null = [];

  loading: boolean = false;

  loadingList: boolean = false;

  loadingPermissionDropdowns: boolean = false;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingList: observable,
      collegeInfo: observable,
      collegeList: observable,
      collegeBasicList: observable,
      pmsTemplate: observable,
      permissionList: observable,
      permissionByLevelsList: observable,
      loadingPermissionDropdowns: observable,
      getCollegeInfo: action,
      getPermissionTemplate: action,
      getPermissionListByCollege: action,
      updateCollegeInfo: action,
      getPermissionListByLevel: action,
    });

    this.rootStore = rootStore;
  }

  getCollegeInfo = async (collegeId: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/SystemManagement/GetCollegeInfo/${collegeId}`);
      this.collegeInfo = res.data;
      this.getPermissionListByCollege(res.data.id);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getCollegeList = async (pageSize: number, skip: number) => {
    runInAction(() => {
      this.loadingList = true;
    })
    try {
      const res = await Axios.get(`api/PlatformManagement/GetCollegeList/${pageSize}/${skip}`);
      this.collegeList.data = res.data.data;
      this.collegeList.total = res.data.total;
      console.log(toJS(this.collegeList));

      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      runInAction(() => {
        this.loadingList = false;
      })
    }
  }

  getCollegeBasicList = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/PlatformManagement/GetCollegeBasicList`);
      this.collegeBasicList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createCollege = async (collegeInfo: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`api/PlatformManagement/CreateCollege`, collegeInfo);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateCollegeInfo = async (collegeInfo) => {
    this.loading = true;
    try {
      const res = await Axios.put(`api/SystemManagement/UpdateCollegeInfo/`, collegeInfo);
      console.log(res.data);

      for (var theme of THEME_NAME_CONVERSION) {
        if (theme.default == res.data.styleSetting) {
          localStorage.THEME = theme.css;
          break;
        }
      }

      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateCollegeStatus = async (collegeId: number) => {
    this.loading = true;
    try {
      const res = await Axios.put(`api/PlatformManagement/UpdateCollegeStatus/${collegeId}`);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getPermissionTemplate = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/SystemManagement/GetDefaultPermissionTemplate`);
      this.pmsTemplate = res.data;
      console.log("Tmp",res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getPermissionListByCollege = async (collegeId: number) => {
    this.loadingPermissionDropdowns = true;
    try {
      const res = await Axios.get(`api/SystemManagement/GetPermissionListByCollege/${collegeId}`);
      runInAction(() => {
        this.permissionList = res.data;
        this.permissionByLevelsList = []
      })

      console.log("Pms List by Collegeid", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loadingPermissionDropdowns = false;
    }
  }

  getPermissionListByLevel = async (levelId: number, collegeId: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/SystemManagement/GetPermissionListByLevel/${levelId}/${collegeId}`);
      this.permissionByLevelsList = res.data;
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createUserRole = async (roleInfo: any) => {
    this.loading = true;
    try {
      const res = await Axios.post('api/SystemManagement/CreateUserRole', roleInfo);
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createPmsLevelByUserRole = async (roleInfo: any) => {
    this.loading = true;
    try {
      const res = await Axios.post('api/SystemManagement/CreatePermissionLevel', roleInfo);
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updatePermissionLevel = async (levelId: number, levelName: string) => {
    console.log(levelName);

    this.loading = true;
    try {
      const res = await Axios.put(`api/SystemManagement/UpdatePermissionLevel/${levelId}/${levelName}`);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deletePermissionLevel = async (levelId: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`api/SystemManagement/DeletePermissionLevel/${levelId}`);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  modifyPermissionStrategy = async (levelId: number, newStrategies: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`api/SystemManagement/ModifyStrategyByPermissionLevel/${levelId}`, newStrategies);
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  uploadCollegeLogo = async (collegeId, formData) => {
    this.loading = true;
    try {
      const res = await Axios.post(`api/SystemManagement/UploadCollegeLogo/${collegeId}`, formData);
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

}
