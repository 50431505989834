export const imageSize = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const signInMethod = {
  traditional: 'Vmor',
  sso: 'SSO',
}

export enum Language {
  ENGLISH = 'en-AU',
  CHINESE_SIMP = 'zh-CN',
  CHINESE_TRAD = 'zh-TW'
}

export interface ChartData {
  category: string;
  totalCount: number;
  detailData: any;
}

export enum PermissionCategory {
  SYSTEM = "System Management",
  TEACHER = "Teacher Dashboard",
  STUDENT = "Student Dashboard",
  SCHOOL = "School Dashboard",
}

export enum LearnerAttribute {
  REQUIRED_ATTENTION = 0,
  SOMETIMES = 2,
  USUALLY = 4,
  ALWAYS = 6,
}

export const onConvertLearnerAttributeToIndex = (attribute: string) => {
  if (!attribute || attribute.includes("Required")) return 0;
  if (attribute.includes("Sometime")) return 1;
  if (attribute.includes("Usually")) return 2;
  if (attribute.includes("Always")) return 3;
  return 0;
}

// Check Permission for Inner Page Feature
export const getPermissionValidity = (
  category: PermissionCategory, sectionName: string, tabName?: string
) => {
  let pms = typeof window !== "undefined" && JSON.parse(localStorage.PERMISSIONS);
  if (!Array.isArray(pms)) {
    localStorage.clear();
    location.reload();
  }
  pms = pms.find(p => p.category === category);
  if (tabName) {
    return pms && pms.sectionList.length > 0 && pms.sectionList.find(s => s.sectionName === sectionName)
      && pms.sectionList.find(s => s.sectionName === sectionName).tabList.length > 0
      && pms.sectionList.find(s => s.sectionName === sectionName).tabList.includes(tabName)
  }
  return pms && pms.sectionList.length > 0 && pms.sectionList.find(s => s.sectionName === sectionName)
    && pms.sectionList.find(s => s.sectionName === sectionName).tabList.length > 0
}

// Check Permission for Menu Access
export const getMenuAccessValidity = (category: PermissionCategory, sectionName?: string) => {
  let pms = typeof window !== "undefined" && JSON.parse(localStorage.PERMISSIONS)
  if (!Array.isArray(pms)) {
    localStorage.clear();
    location.reload();
  }
  pms = pms.find(p => p.category === category);
  if (sectionName)
    return pms && pms.sectionList.length > 0 && pms.sectionList.find(s => s.sectionName === sectionName) &&
      pms.sectionList.find(s => s.sectionName === sectionName).tabList.length > 0;
  return pms && pms.sectionList.length > 0;
}