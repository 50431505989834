import './src/styles/global.css';
import React, { useEffect } from 'react';
import MenuBar from "./src/components/menu"
import NotificationProvider from './src/components/notification-provider';
import { SnackbarProvider } from 'notistack';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalConfig } from "./auth-config";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import LoginPage from "./src/pages/login";
import { Language, signInMethod } from './src/constants/options';
import { I18nextProvider, useTranslation } from 'react-i18next';
import '../react/src/localization/i18n';

const msalInstance = new PublicClientApplication(msalConfig);

const StyleWrapper = ({ children }) => {
    const { i18n } = useTranslation();
    useEffect(() => {
        if (typeof window !== "undefined" && localStorage.LANG) {
            i18n.changeLanguage(localStorage.LANG);
        } else {
            i18n.changeLanguage(Language.ENGLISH);
            localStorage.LANG = Language.ENGLISH
        }

    }, []);
    return (
        <I18nextProvider i18n={i18n}>
            <div className={typeof window !== "undefined" ? localStorage.THEME : 'theme-primary'}>
                {children}
            </div>
        </I18nextProvider >
    )
}

export function wrapPageElement({ element, props }) {
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it
    return (
        <MsalProvider instance={msalInstance}>
            <SnackbarProvider maxSnack={1}>
                <NotificationProvider>
                    <StyleWrapper>
                        {
                            typeof window !== "undefined" && !localStorage.SIGN_IN_METHOD || localStorage.SIGN_IN_METHOD == signInMethod.sso
                                ?
                                <>
                                    <UnauthenticatedTemplate>
                                        <LoginPage />
                                    </UnauthenticatedTemplate>
                                    <AuthenticatedTemplate>
                                        <div className={`grid grid-cols-6 gap-4`}>
                                            <div className="col-span-1" style={{ minHeight: '100vh' }}>
                                                <MenuBar />
                                            </div>
                                            <div className="col-span-5">
                                                {element}
                                            </div>
                                        </div>
                                    </AuthenticatedTemplate>
                                </>
                                :
                                <div className={`grid grid-cols-6 gap-4`}>
                                    <div className="col-span-1" style={{ minHeight: '100vh' }}>
                                        <MenuBar />
                                    </div>
                                    <div className="col-span-5">
                                        {element}
                                    </div>
                                </div>
                        }

                    </StyleWrapper>
                </NotificationProvider>
            </SnackbarProvider>
        </MsalProvider >
    )
}