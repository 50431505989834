import { makeStyles } from "@material-ui/core";

// Styles used by native base
export const PRIMARY_BUTTON_COLOR = '#130F26';
export const PRIMARY_TEXT_COLOR = '#0C2C52';
export const MARGIN_HORIZONTAL = 20;
export const MARGIN_VERTICAL = 7;
export const BACKGROUND_COLOR = 'rbg(245, 245, 245)';
export const TAG_STYLE = 'border border-barPrimary rounded-xl text-barPrimary p-1 text-center';

// Chart Styles
export const CHART_WIDTH = '100%';
export const CHART_HEIGHT = '250px';
export const CHART_BAR_WIDTH = '30px';
export const CHART_BAR_COLOR = '#3eba8a';

// Stacked Lines Limited Subjects
export const PRIORITY_SUBJECT_1 = 'English';
export const PRIORITY_SUBJECT_2 = 'Mathematics';

// Website Theme
export const MODAL_WINDOW = 'bg-white absolute left-1/2 top-1/2 border-t-8 border-barPrimary';
export const BAR_RADIUS = 30;
export const THEME_CLASS = [
  {
    name: "Primary",
    theme: 'theme-primary',
    background: "#E5E5E5",
    button: "#130F26",
    headerBg: "#F9F9F9",
    headerTextColor: "#CCA1A4",
    barPrimary: "#A3363C",
    chartColorLibrary: [
      '#55CFE4',
      '#74A5F7',
      '#A6DEAC',
      '#ffcf9b',
      '#FFB3B2',
    ]
  },
  {
    name: "Ocean",
    theme: "theme-ocean",
    background: "#E5E5E5",
    button: "#7b7b7b",
    headerBg: "#F9F9F9",
    headerTextColor: "#6a6cc9",
    barPrimary: "#6a6cc9",
    chartColorLibrary: [
      '#0d0f5a',
      '#191c8a',
      '#4245d1',
      '#6a6cc9',
      '#a2a3ed',
      '#c7c7f1',
    ]
  }
]

export const THEME_NAME_CONVERSION = [
  { default: "Primary", css: "theme-primary" },
  { default: "Ocean", css: "theme-ocean" },
]

export const getCurrentThemeColour = () => {
  var currentTheme = "";
  if (typeof window !== "undefined" && localStorage.THEME) 
    currentTheme = "." + localStorage.THEME.toString();
  else 
    currentTheme = '.theme-ocean';
  return getComputedStyle(
    document.querySelector(currentTheme), ""
  ).getPropertyValue('--barPrimary');
}

export const useStyles = makeStyles({
  textField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--barPrimary)"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--barPrimary)"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--barPrimary)"
    },
    "& .MuiOutlinedInput-input": {
      color: "var(--barPrimary)"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "var(--barPrimary)"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "var(--barPrimary)"
    },
    "& .MuiInputLabel-outlined": {
      color: "var(--barPrimary)"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "var(--barPrimary)"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "var(--barPrimary)"
    }
  },

  root: {
    "& .MuiOutlinedInput-input": {
      color: "green"
    },
    "& .MuiInputLabel-root": {
      color: "green"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "red"
    },
    "&:hover .MuiInputLabel-root": {
      color: "red"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "purple"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "purple"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple"
    }
  }

});
