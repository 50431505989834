/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ImportantDevicesOutlinedIcon from '@material-ui/icons/ImportantDevicesOutlined';
import PermDataSettingOutlinedIcon from '@material-ui/icons/PermDataSettingOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import logo from '../images/logo3.png'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import useStores from '../hooks/use-stores';
import { navigate } from 'gatsby-link';
import CustomisedModal from './customised-modal';
import { Button } from '@material-ui/core';
import { useMsal } from "@azure/msal-react";
import { getMenuAccessValidity, PermissionCategory, signInMethod } from '../constants/options';
import PhonelinkLockIcon from '@material-ui/icons/PhonelinkLock';
import { useTranslation } from 'react-i18next';

export function MenuBar() {
  const { userStore, rootStore } = useStores();
  const { instance } = useMsal();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentSubMenu, setCurrentSubMenu] = useState(
    typeof window !== "undefined" && localStorage.getItem('ACTIVE_SUBMENU') ? localStorage.getItem('ACTIVE_SUBMENU') : ''
  );
  const menuDivClass = 'flex w-full p-3 2xl:pl-10 h-auto';
  const menuSecDivClass = 'flex pl-8 2xl:pl-16 py-2 2xl:py-3 h-auto';
  const menuButtonClass = 'mt-1 2xl:mt-0 md:col-span-5 text-white text-xs uppercase';
  const iconStyle = 'mr-3 text-white';

  useEffect(() => {
    if (typeof window !== "undefined" && localStorage.PERMISSIONS) {
      if (Object.keys(JSON.parse(localStorage.PERMISSIONS)).length == 0) {
        rootStore.notify("No Permission Found in this User", 'warning');
        userStore.signOut();
      }
    }
  }, [userStore.userToken]);

  const onCollapseSubMenu = (target) => {
    if (currentSubMenu == target) {
      setCurrentSubMenu('');
      localStorage.setItem('ACTIVE_SUBMENU', '');
    }
    else {
      setCurrentSubMenu(target);
      localStorage.setItem('ACTIVE_SUBMENU', target);
    }
  }

  const signOut = () => {
    userStore.signOut();
    if (typeof window !== "undefined" && localStorage.SIGN_IN_METHOD == "SSO") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    } else {
      localStorage.SIGN_IN_METHOD = signInMethod.sso;
    }
  }

  return (
    userStore.isAuthed && typeof window !== "undefined" && localStorage.PERMISSIONS ?
      <span>
        <div className="bg-barPrimary flex flex-col h-full sticky">
          <div className="">
            <div className="">
              <img
                className="cursor-pointer"
                src={logo}
                alt="logo"
                style={{ margin: "0 auto" }}
              />
            </div>

            {
              getMenuAccessValidity(PermissionCategory.TEACHER) &&
              <a
                className={`${menuDivClass} ${location.pathname.includes("/dashboard") && 'bg-white text-barPrimary'}`}
                href='/dashboard'
                onClick={e => {
                  e.preventDefault();
                  delete localStorage.SD_TAB_HISTORY;
                  navigate("/dashboard");
                }}
              >
                <DashboardOutlinedIcon className={`${iconStyle} ${location.pathname.includes("/dashboard") && 'text-barPrimary'}`} />
                <div className={`${menuButtonClass} ${location.pathname.includes("/dashboard") && 'bg-white text-barPrimary'}`}>
                  {t('DASHBOARD')}
                </div>
              </a>
            }


            {/* Student */}
            {
              getMenuAccessValidity(PermissionCategory.STUDENT, "StudentList") &&
              <a
                className={`${menuDivClass} ${location.pathname.includes("/student-") && 'bg-white text-barPrimary'}`}
                href='/student-overview'
                onClick={e => {
                  e.preventDefault();
                  navigate("/student-overview");
                }}
              >
                <PeopleAltOutlinedIcon className={`${iconStyle} ${location.pathname.includes("/student-") && 'text-barPrimary'}`} />
                <div className={`${menuButtonClass} ${location.pathname.includes("/student-") && 'bg-white text-barPrimary'}`}>
                  Student Dashboard
                </div>
              </a>
            }

            {/* School Analytics */}
            {getMenuAccessValidity(PermissionCategory.SCHOOL) &&
              <>
                <a
                  className={
                    `${menuDivClass} cursor-pointer`}
                  onClick={(e) => {
                    e.preventDefault();
                    onCollapseSubMenu('school-analytics');
                  }}
                >
                  <HomeOutlinedIcon className={`${iconStyle}`} />
                  <div className={`${menuButtonClass}`}>
                    School Dashboard
                    <ArrowDropDownIcon />
                  </div>
                </a>
                <SlideDown
                  className={'pure-menu pure-menu-scrollable '}
                  closed={currentSubMenu != 'school-analytics'}
                >
                  {getMenuAccessValidity(PermissionCategory.SCHOOL, "Dashboard") &&
                    <a
                      className={`${menuSecDivClass} ${location.pathname.includes("/school-dashboard") && 'bg-white text-barPrimary'}`}
                      href='/school-dashboard'
                      onClick={e => {
                        e.preventDefault();
                        navigate("/school-dashboard");
                      }}
                    >
                      <DashboardOutlinedIcon className={`${iconStyle} ${location.pathname.includes("/school-dashboard") && 'text-barPrimary'}`} />
                      <div className={`${menuButtonClass} ${location.pathname.includes("/school-dashboard") && 'bg-white text-barPrimary'}`}>
                        Overview
                      </div>
                    </a>
                  }
                  {getMenuAccessValidity(PermissionCategory.SCHOOL, "SchoolExecutive") &&
                    <a
                      className={`${menuSecDivClass} ${location.pathname.includes('/school-executives') && !location.pathname.includes('/academics') && 'bg-white text-barPrimary'}`}
                      href='/school-executives'
                      onClick={e => {
                        e.preventDefault();
                        navigate("/school-executives");
                      }}
                    >
                      <TimelineOutlinedIcon className={`${iconStyle} ${location.pathname.includes('/school-executives') && 'bg-white text-barPrimary'}`} />
                      {/* <SchoolOutlinedIcon className={`${iconStyle} ${location.pathname.includes('/academics') && !location.pathname.includes('/academics') && 'bg-white text-barPrimary'}`} /> */}
                      <div className={`${menuButtonClass} ${location.pathname.includes('/school-executives') && !location.pathname.includes('/academics') && 'bg-white text-barPrimary'}`}>
                        {/* ACADEMICS */}
                        {t('SCHOOL_EXECUTIVES')}
                      </div>
                    </a>
                  }

                  {
                    getMenuAccessValidity(PermissionCategory.SCHOOL, "Academic") &&
                    <a
                      className={`${menuSecDivClass} ${location.pathname.includes('/academics') && 'bg-white text-barPrimary'}`}
                      href='/academics'
                      onClick={e => {
                        e.preventDefault();
                        navigate("/academics");
                      }}
                    >
                      <SchoolOutlinedIcon className={`${iconStyle} ${location.pathname.includes('/academics') && 'bg-white text-barPrimary'}`} />
                      {/* <AddBoxOutlinedIcon className={`${iconStyle} ${location.pathname.includes('/academics') && 'bg-white text-barPrimary'}`} /> */}
                      <div className={`${menuButtonClass} ${location.pathname.includes('/academics') && 'bg-white text-barPrimary'}`}>
                        {t('ACADEMICS')}
                      </div>
                    </a>
                  }

                  {
                    getMenuAccessValidity(PermissionCategory.SCHOOL, "Attendance") &&
                    <a
                      className={`${menuSecDivClass} ${location.pathname.includes('/attendence') && 'bg-white text-barPrimary'}`}
                      href='/attendence'
                      onClick={e => {
                        e.preventDefault();
                        navigate("/attendence");
                      }}
                    >
                      <QueryBuilderOutlinedIcon className={`${iconStyle} ${location.pathname.includes('/attendence') && 'bg-white text-barPrimary'}`} />
                      <div className={`${menuButtonClass} ${location.pathname.includes('/attendence') && 'bg-white text-barPrimary'}`}>
                        {t('ATTENDANCE')}
                      </div>
                    </a>
                  }
                  {
                    getMenuAccessValidity(PermissionCategory.SCHOOL, "Behavior") &&
                    <a
                      className={`${menuSecDivClass} ${location.pathname.includes('/behavioral') && 'bg-white text-barPrimary'}`}
                      href='/behavioral'
                      onClick={e => {
                        e.preventDefault();
                        navigate("/behavioral");
                      }}
                    >
                      <AssessmentOutlinedIcon className={`${iconStyle} ${location.pathname.includes('/behavioral') && 'bg-white text-barPrimary'}`} />
                      <div className={`${menuButtonClass}  ${location.pathname.includes('/behavioral') && 'bg-white text-barPrimary'}`}>
                        {t('BEHAVIOR')}
                      </div>
                    </a>
                  }
                  {/*
                    getMenuAccessValidity(PermissionCategory.SCHOOL, "Learning") &&
                    <a
                      className={`${menuSecDivClass} ${location.pathname.includes('/learning') && 'bg-white text-barPrimary'}`}
                      href='/error-message'
                      onClick={e => {
                        e.preventDefault();
                        navigate("/error-message");
                      }}
                    >
                      <AddBoxOutlinedIcon className={`${iconStyle} ${location.pathname.includes('/learning') && 'bg-white text-barPrimary'}`} />
                      <div className={`${menuButtonClass} ${location.pathname.includes('/learning') && 'bg-white text-barPrimary'}`}>
                        {t('LEARNING')}
                      </div>
                    </a>
                */}
                  {/* <a
                    className={`${menuSecDivClass} ${location.pathname.includes('/student-tasks') && 'bg-white text-barPrimary'}`}
                    href='/student-tasks'
                    onClick={e => {
                      e.preventDefault();
                      navigate("/student-tasks");
                    }}
                  >
                    <TimelineOutlinedIcon className={`${iconStyle} ${location.pathname.includes('/student-tasks') && 'bg-white text-barPrimary'}`} />
                    <div className={`${menuButtonClass} ${location.pathname.includes('/student-tasks') && 'bg-white text-barPrimary'}`}>
                      SCHOOL EXECUTIVES
                    </div>
                  </a> */}
                  {/*
                    getMenuAccessValidity(PermissionCategory.SCHOOL, "Wellbeing") &&
                    <a
                      className={`${menuSecDivClass} ${location.pathname.includes('/well-being') && 'bg-white text-barPrimary'}`}
                      href='/error-message'
                      onClick={e => {
                        e.preventDefault();
                        navigate("/error-message");
                      }}
                    >
                      <FavoriteBorderIcon className={`${iconStyle} ${location.pathname.includes('/well-being') && 'bg-white text-barPrimary'}`} />
                      <div className={`${menuButtonClass}  ${location.pathname.includes('/well-being') && 'bg-white text-barPrimary'}`}>
                        {t('WELLBEING')}
                      </div>
                    </a>
                  */}
                </SlideDown>
              </>
            }

            {/* System Management */}
            {
              getMenuAccessValidity(PermissionCategory.SYSTEM) &&
              <>
                <a
                  className={`${menuDivClass} cursor-pointer`}
                  onClick={(e) => {
                    e.preventDefault();
                    onCollapseSubMenu('system-management');
                  }}
                >
                  <SettingsOutlinedIcon className={`${iconStyle}`} />
                  <div className={`${menuButtonClass}`}>
                    {t('SYSTEM_MANAGEMENT')}
                    <ArrowDropDownIcon />
                  </div>
                </a>
                <SlideDown
                  className={'pure-menu pure-menu-scrollable '}
                  closed={currentSubMenu != 'system-management'}
                >
                  {
                    getMenuAccessValidity(PermissionCategory.SYSTEM, "General") &&
                    <a
                      className={`${menuSecDivClass} ${location.pathname.includes("general") && 'bg-white text-barPrimary'}`}
                      href='/system-management/general'
                      onClick={e => {
                        e.preventDefault();
                        navigate("/system-management/general");
                      }}
                    >
                      <InfoOutlinedIcon className={`${iconStyle} ${location.pathname.includes("general") && 'bg-white text-barPrimary'}`} />
                      <div className={`${menuButtonClass} ${location.pathname.includes("general") && 'bg-white text-barPrimary'}`}>
                        {t('GENERAL')}
                      </div>
                    </a>
                  }

                  {
                    getMenuAccessValidity(PermissionCategory.SYSTEM, "User") &&
                    <a
                      className={`${menuSecDivClass} ${location.pathname.includes("system-management/user") && 'bg-white text-barPrimary'}`}
                      href='/system-management/user'
                      onClick={e => {
                        e.preventDefault();
                        navigate("/system-management/user");
                      }}
                    >
                      <PersonAddOutlinedIcon className={`${iconStyle} ${location.pathname.includes("system-management/user") && 'bg-white text-barPrimary'}`} />
                      <div className={`${menuButtonClass} ${location.pathname.includes("system-management/user") && 'bg-white text-barPrimary'}`}>
                        {t('USER')}
                      </div>
                    </a>
                  }

                  {
                    getMenuAccessValidity(PermissionCategory.SYSTEM, "Maintenance") &&
                    <a
                      className={`${menuSecDivClass} ${location.pathname.includes("maintenance") && 'bg-white text-barPrimary'}`}
                      href='/system-management/maintenance'
                      onClick={e => {
                        e.preventDefault();
                        navigate("/system-management/maintenance");
                      }}
                    >
                      <PermDataSettingOutlinedIcon className={`${iconStyle} ${location.pathname.includes("maintenance") && 'bg-white text-barPrimary'}`} />
                      <div className={`${menuButtonClass} ${location.pathname.includes("maintenance") && 'bg-white text-barPrimary'}`}>
                        {t('MAINTENANCE')}
                      </div>
                    </a>
                  }
                  {/* {
                    permission.find(p => p.category === "System").sectionList.length > 0 &&
                    permission.find(p => p.category === "System").sectionList.find(s => s.sectionName === "ParentModule") && */}
                  {/** 
                  <a
                    className={`${menuSecDivClass} ${location.pathname.includes("parent-module") && 'bg-white text-barPrimary'}`}
                    href='/system-management/parent-module'
                    onClick={e => {
                      e.preventDefault();
                      navigate("/system-management/parent-module");
                    }}
                  >
                    <PhonelinkLockIcon className={`${iconStyle} ${location.pathname.includes("parent-module") && 'bg-white text-barPrimary'}`} />
                    <div className={`${menuButtonClass} ${location.pathname.includes("parent-module") && 'bg-white text-barPrimary'}`}>
                      {t('PARENT_MODULE')}
                    </div>
                  </a>*/}
                  {/* } */}
                </SlideDown>
              </>
            }

            {/* Platform Management */}
            {
              false &&
              <>
                <a
                  className={`${menuDivClass} cursor-pointer`}
                  onClick={(e) => {
                    e.preventDefault();
                    onCollapseSubMenu('platform-management');
                  }}
                >
                  <ImportantDevicesOutlinedIcon className={`${iconStyle}`} />
                  <div className={`${menuButtonClass}`}>
                    {t('PLATFORM_MANAGEMENT')}
                    <ArrowDropDownIcon />
                  </div>
                </a>

                <SlideDown
                  className={'pure-menu pure-menu-scrollable '}
                  closed={currentSubMenu != 'platform-management'}
                >
                  <a
                    className={`${menuSecDivClass} ${location.pathname.includes("data-management") && 'bg-white text-barPrimary'}`}
                    href='/data-management'
                  >
                    <PermDataSettingOutlinedIcon className={`${iconStyle} ${location.pathname.includes("data-management") && 'bg-white text-barPrimary'}`} />
                    <div className={`${menuButtonClass} ${location.pathname.includes("data-management") && 'bg-white text-barPrimary'}`}>
                      {t('DATA')}
                    </div>
                  </a>
                  <a
                    className={`${menuSecDivClass} ${location.pathname.includes("schools") && 'bg-white text-barPrimary'}`}
                    href='/platform-management/schools'
                  >
                    <SchoolOutlinedIcon className={`${iconStyle} ${location.pathname.includes("schools") && 'bg-white text-barPrimary'}`} />
                    <div className={`${menuButtonClass} ${location.pathname.includes("schools") && 'bg-white text-barPrimary'}`}>
                      {t('SCHOOLS')}
                    </div>
                  </a>
                </SlideDown>
              </>
            }
          </div>
          <button
            className={`py-3 w-full text-right absolute bottom-0 right-0 text-white text-xs 2xl-text-sm`}
            onClick={(e) => {
              e.preventDefault();
              setShowModal(true);
            }}
          >
            <ExitToAppOutlinedIcon style={{ marginBottom: 3 }} className="text-white" />
            {/* <span className="ml-2">LOG OUT</span> */}

            <span className="mx-2 uppercase">{t('SIGN_OUT')}</span>
          </button>
        </div>
        <CustomisedModal showModal={showModal}>
          <div
            className="bg-white absolute top-1/3 left-1/2 p-8 border-t-8 border-barPrimary"
            style={{ transform: 'translate(-50%)' }}
          >
            <div className="text-barPrimary w-full text-center text-xl">{t('SIGN_OUT_CHECK')}?</div>
            <div className="text-center m-4 mt-8">
              <Button
                type="button"
                className="bg-buttonPrimary text-white py-2 mx-2 text-sm 2xl:text-md uppercase"
                onClick={() => setShowModal(false)}
              >
                {t('CANCEL')}
              </Button>
              <Button
                type="button"
                className="bg-buttonPrimary text-white py-2 mx-2 text-sm 2xl:text-md uppercase"
                onClick={signOut}
              >
                {t('SIGN_OUT')}
              </Button>
            </div>
          </div>
        </CustomisedModal>
      </span>
      : <div></div>
  );
}

export default MenuBar;
