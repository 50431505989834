import { action, observable, makeObservable, toJS } from 'mobx';
import { Axios } from '../utilities/network';

export default class ParentStore {
    loading: boolean = false;

    parentModuleList: any = [];

    rootStore;

    constructor(rootStore) {
        makeObservable(this, {
            loading: observable,
            parentModuleList: observable,
            getParentPortalModuleList: action,
            updateParentPortalModuleDisplay: action,
        });
        this.rootStore = rootStore;
    }

    getParentPortalModuleList = async () => {
        this.loading = true;
        try {
            const res = await Axios.get('/api/SystemManagement/GetParentPortalModuleList');
            console.log('Parent Module List', res.data);
            this.parentModuleList = res.data;
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        } finally {
            this.loading = false;
        }
    }

    updateParentPortalModuleDisplay = async (moduleId: number, display: boolean) => {
        this.loading = true;
        try {
            const res = await Axios.put(`/api/SystemManagement/UpdateParentPortalModuleDisplay/${moduleId}/${display}`);
            console.log('Update Parent Module List', res.data);
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.resolve(err.data);
        } finally {
            this.loading = false;
        }
    }

}