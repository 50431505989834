import axios from 'axios';
import { action, observable, makeObservable, runInAction } from 'mobx';
import { Axios } from '../utilities/network';

export default class TeacherStore {

  loading: boolean = false;
  loadingTaskDueList: boolean = false;
  teacherTimetable: any = [];
  teacherLinks: any = [];
  dailyMessageList: any = [];
  teacherStaffMsgList: any = [];
  teacherAbsenceList: any = [];
  calendarList: any = [];
  taskDueDateList: any = [];
  hrCodeSubjectPerformList: any = [];
  teacherSubjectPerformList: any = [];
  teacherMarkingStatistics: any = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      loadingTaskDueList: observable,
      teacherTimetable: observable,
      dailyMessageList: observable,
      teacherStaffMsgList: observable,
      teacherAbsenceList: observable,
      calendarList: observable,
      taskDueDateList: observable,
      hrCodeSubjectPerformList: observable,
      teacherMarkingStatistics: observable,
      getTeacherTimetable: action,
      getLinksByTeacherId: action,
      updateLinkCategorySetting: action,
      getTeacherDailyMessage: action,
      updateDailyMessageCategorySetting: action,
      getTeacherStaffMessage: action,
      getTeacherAbsenceList: action,
      getCalendarList: action,
      getTaskDueDateList: action,
      addCalendarEvent: action,
      updateCalendarEvent: action,
      deleteCalendarEvent: action,
      getTeacherSubjectPerformance: action,
      getTeacherMarkingStatistics: action,
    });

    this.rootStore = rootStore;
  }

  getTeacherTimetable = async (uid: number, date: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/Teacher/GetTeacherTimetable/${uid}/${date}`);
      console.log("Timetable", res.data);
      this.teacherTimetable = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  getLinksByTeacherId = async (uid: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/Teacher/GetLinksByTeacherId/${uid}`);
      console.log("Links", res.data);
      this.teacherLinks = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  updateLinkCategorySetting = async (req: any) => {
    try {
      const res = await Axios.put(`api/Teacher/UpdateLinkCategorySetting/`, req);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    }
  }

  getTeacherDailyMessage = async (uid: number, date: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/Teacher/GetTeacherDailyMessage/${uid}/${date}`);
      console.log("Daily Msg", res.data);
      this.dailyMessageList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  updateDailyMessageCategorySetting = async (req: any) => {
    try {
      const res = await Axios.put(`api/Teacher/UpdateDailyMessageCategorySetting/`, req);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    }
  }

  getTeacherStaffMessage = async (uid: number, date: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/Teacher/GetTeacherStaffMessage/${uid}/${date}`);
      console.log("Teacher Staff Msg", res.data);
      this.teacherStaffMsgList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  updateStaffMessageCategorySetting = async (req: any) => {
    try {
      const res = await Axios.put(`api/Teacher/UpdateStaffMessageCategorySetting/`, req);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    }
  }

  getTeacherAbsenceList = async (date: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/Teacher/GetTeacherAbsenceList/${date}`);
      console.log("Teacher Absence Msg", res.data);
      this.teacherAbsenceList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  getCalendarList = async (uid: string, startDate: string, endDate: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/Teacher/GetCalendarList/${uid}/${startDate}/${endDate}`);
      console.log("Calendar List", res.data);
      this.calendarList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  getTaskDueDateList = async (uid: string, date: string) => {
    this.loadingTaskDueList = true;
    try {
      const res = await Axios.get(`api/Teacher/GetTaskDueDateList/${uid}/${date}`);
      console.log("Task Due Date List", res.data);
      this.taskDueDateList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loadingTaskDueList = false;
    }
  }

  getHomeroomStudentSubjectPerformanceList = async (semesterId: number, homeRoomCode: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/Teacher/GetHomeroomStudentSubjectPerformanceList/${semesterId}/${homeRoomCode}`);
      console.log("Homeroom Student Subject Performance List", res.data);
      this.hrCodeSubjectPerformList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  addCalendarEvent = async (eventModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`api/Teacher/AddCalendarEvent`, eventModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  updateCalendarEvent = async (eventModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`api/Teacher/UpdateCalendarEvent`, eventModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  deleteCalendarEvent = async (eventId: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`api/Teacher/DeleteCalendarEvent/${eventId}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  getTeacherSubjectPerformance = async (uid: number, semesterId: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/Teacher/GetTeacherSubjectPerformance/${uid}/${semesterId}`);
      console.log("GetTeacherSubjectPerformance", res.data);
      const compare = (a, b) => {
        if ((a.subject < b.subject)) return -1;
        if (a.subject > b.subject) return 1;
        return 0;
      }
      this.teacherSubjectPerformList = res.data.sort(compare);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  getTeacherMarkingStatistics = async (uid: number, semesterId: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`api/Teacher/GetTeacherMarkingStatistics/${uid}/${semesterId}`);
      console.log("GetTeacherMarkingStatistics", res.data);
      this.teacherMarkingStatistics = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }
}
