import { action, observable, makeObservable, toJS } from 'mobx';
import { Axios } from '../utilities/network';

export const AcademicChartCategory = {
  feedbackByMarkingDays: "Average of Submission to Marking Days by Class Teacher",
  feedbackByDueDateMarkingDate: "Average Of Days Between Due Date And Marking Date by Class Teacher",
  feedbackPerStudentByClass: "Average Feedback Per Student by Class Teacher",
}

export const WrittenFeedbackType = {
  marking: "Marking Feedback",
  file: "Feedback File",
  rubric: "Marking Rubric",
}

export const DistributionRange = {
  one: "0.00-0.10",
  two: "0.10-0.20",
  three: "0.20-0.30",
  four: "0.30-0.40",
  five: "0.40-0.50",
  six: "0.50-0.60",
  seven: "0.60-0.70",
  eight: "0.70-0.80",
  nine: "0.80-0.90",
  ten: "0.90-1.00",
  eleven: "1.00-1.10"
}

export default class AcademicStore {
  loading: boolean = false;

  loadingExternal: boolean = false;

  loadingPATDetail: boolean = false;

  advanceLoading: boolean = false;

  feedbackAnalysisData: any | null = null;

  submissionAnalysisData: any | null = null;

  performanceAnalysisData: any | null = null;

  averagePercentageByStudent = [];

  markDistributionByHomeClass: any = [];

  averagePercentageByStudentAdvance: any = [];

  markDistributionByHomeClassAvance: any = [];

  // Teacher list in feedback and submission analysis
  resultTeacherList: any = []

  advancedSelectedTeacherList: any = []

  // Feedback Analysis 
  feedbackByMarkingDaysList = [];

  feedbackByDueDateMarkingDateList = [];

  feedbackPerStudentByClass = [];

  feedbackTableList = [];

  nonSubmissionByTeacherList = [];

  writtenFeedbackList = [];

  feedbackByMarkingDaysListAdvance = [];

  feedbackByDueDateMarkingDateListAdvance = [];

  feedbackPerStudentByClassAdvance = [];

  feedbackTableListAdvance = [];

  nonSubmissionByTeacherListAdvance = [];

  writtenFeedbackListAdvance = [];

  // Submission Analysis
  nonSubmissionByTeacherSAList = [];

  nonSubmissionByDomainTeacherSAList = [];

  unsubmittedTaskSAList = [];

  countNonSubmissionSAList = [];

  nonSubmissionByTeacherSAListAdvance = [];

  nonSubmissionByDomainTeacherSAListAdvance = [];

  unsubmittedTaskSAListAdvance = [];

  countNonSubmissionSAListAdvance = [];

  patOverviewData: any = [];

  patOverviewDetail: any = [];

  sPerformanceGrowthData: any = [];

  submissionStatusData: any = [];

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      loadingExternal: observable,
      loadingPATDetail: observable,
      advanceLoading: observable,
      submissionAnalysisData: observable,
      feedbackAnalysisData: observable,
      performanceAnalysisData: observable,
      averagePercentageByStudentAdvance: observable,
      markDistributionByHomeClassAvance: observable,
      averagePercentageByStudent: observable,
      markDistributionByHomeClass: observable,

      // Teacher list in feedback and submission analysis
      resultTeacherList: observable,
      advancedSelectedTeacherList: observable,

      // Feedback Analysis
      feedbackByMarkingDaysList: observable,
      feedbackByDueDateMarkingDateList: observable,
      feedbackPerStudentByClass: observable,
      feedbackTableList: observable,
      nonSubmissionByTeacherList: observable,
      writtenFeedbackList: observable,
      feedbackByMarkingDaysListAdvance: observable,
      feedbackByDueDateMarkingDateListAdvance: observable,
      feedbackPerStudentByClassAdvance: observable,
      feedbackTableListAdvance: observable,
      nonSubmissionByTeacherListAdvance: observable,
      writtenFeedbackListAdvance: observable,
      // Submission Analysis
      nonSubmissionByTeacherSAList: observable,
      nonSubmissionByDomainTeacherSAList: observable,
      unsubmittedTaskSAList: observable,
      countNonSubmissionSAList: observable,
      nonSubmissionByTeacherSAListAdvance: observable,
      nonSubmissionByDomainTeacherSAListAdvance: observable,
      unsubmittedTaskSAListAdvance: observable,
      countNonSubmissionSAListAdvance: observable,
      patOverviewData: observable,
      patOverviewDetail: observable,
      sPerformanceGrowthData: observable,
      getFeedbackAnalysisData: action,
      processingFeedbackAnalysisData: action,
      getSubmissionAnalysisData: action,
      processingSubmissionAnalysisData: action,
      getPerformanceAnalysisData: action,
      getAveragePercentageByStudent: action,
      getMarkDistributionByHomeClass: action,
      setAdvancedSelectedTeacherList: action,
      getSchoolPATOverview: action,
    });

    this.rootStore = rootStore;
  }

  getFeedbackAnalysisData = async (data, advance?: boolean) => {
    this.loading = true;
    try {
      const res = await Axios.post('/api/Academic/GetFeedbackAnalysis', data);
      this.feedbackAnalysisData = res.data;
      console.log("analysis data",res.data);
      this.processingFeedbackAnalysisData(res.data, advance);
      return Promise.resolve(res.data);
    } catch (err) {
      // this.attendanceData = [];
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  processingFeedbackAnalysisData = (dataSource, advance?: boolean) => {
    // teacher list
    this.resultTeacherList = dataSource.teacherList.map(data => data.substring(data.indexOf(" ") + 1, data.length));

    if (advance) {
      // First 3 charts
      if (dataSource.chartData.length > 0) {
        for (var data of dataSource.chartData) {
          if (data.category == AcademicChartCategory.feedbackByMarkingDays) {
            this.feedbackByMarkingDaysListAdvance = data.detailData;
            console.log(toJS(this.feedbackByMarkingDaysList));

          }
          else if (data.category == AcademicChartCategory.feedbackByDueDateMarkingDate) {
            this.feedbackByDueDateMarkingDateListAdvance = data.detailData;
          }
          else if (data.category == AcademicChartCategory.feedbackPerStudentByClass) {
            this.feedbackPerStudentByClassAdvance = data.detailData;
          }
        }
      }

      // Table in 4th position
      if (dataSource.tableData.length > 0) {
        this.feedbackTableListAdvance = dataSource.tableData;
      }

      // Chart in 5th position
      if (dataSource.nonSubmissionByClassTeacher.detailData.length > 0) {
        this.nonSubmissionByTeacherListAdvance = dataSource.nonSubmissionByClassTeacher.detailData;
      }

      // Chart in final position
      if (dataSource.writtenFeedbackData.length > 0) {
        this.writtenFeedbackListAdvance = dataSource.writtenFeedbackData;
      }
    } else {
      // First 3 charts
      if (dataSource.chartData.length > 0) {
        for (var data of dataSource.chartData) {
          if (data.category == AcademicChartCategory.feedbackByMarkingDays) {
            this.feedbackByMarkingDaysList = data.detailData;
            console.log(toJS(this.feedbackByMarkingDaysList));

          }
          else if (data.category == AcademicChartCategory.feedbackByDueDateMarkingDate) {
            this.feedbackByDueDateMarkingDateList = data.detailData;
          }
          else if (data.category == AcademicChartCategory.feedbackPerStudentByClass) {
            this.feedbackPerStudentByClass = data.detailData;
          }
        }

        // Table in 4th position
        if (dataSource.tableData.length > 0) {
          this.feedbackTableList = dataSource.tableData;
        }

        // Chart in 5th position
        if (dataSource.nonSubmissionByClassTeacher.detailData.length > 0) {
          this.nonSubmissionByTeacherList = dataSource.nonSubmissionByClassTeacher.detailData;
        }

        // Chart in final position
        if (dataSource.writtenFeedbackData.length > 0) {
          this.writtenFeedbackList = dataSource.writtenFeedbackData;
        }
      }


    }

  }

  getSubmissionAnalysisData = async (data, advance?: boolean) => {
    this.loading = true;
    try {
      const res = await Axios.post('/api/Academic/GetSubmissionAnalysis', data);
      this.submissionAnalysisData = res.data;
      this.processingSubmissionAnalysisData(res.data, advance);
      console.log("submission analysis data", res.data, data);
      return Promise.resolve(res.data);
    } catch (err) {
      // this.attendanceData = [];
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  processingSubmissionAnalysisData = (dataSource, advance?: boolean) => {
    if (advance) {
      this.nonSubmissionByTeacherSAListAdvance = dataSource.nonSubmissionByClassTeacher.detailData;
      this.countNonSubmissionSAListAdvance = dataSource.countOfNonSubmissionReasonByStudent.detailData;
      this.nonSubmissionByDomainTeacherSAListAdvance = dataSource.nonSubmissionByDomainGroupByTeacher;
      this.unsubmittedTaskSAListAdvance = dataSource.unsubmittedTaskByStudent;
    } else {
      this.nonSubmissionByTeacherSAList = dataSource.nonSubmissionByClassTeacher.detailData;
      this.countNonSubmissionSAList = dataSource.countOfNonSubmissionReasonByStudent.detailData;
      this.nonSubmissionByDomainTeacherSAList = dataSource.nonSubmissionByDomainGroupByTeacher;
      this.unsubmittedTaskSAList = dataSource.unsubmittedTaskByStudent;
    }

  }

  getPerformanceAnalysisData = async (data) => {
    this.loading = true;
    try {
      const res = await Axios.post('/api/Academic/GetPerformanceAnalysis', data);
      this.performanceAnalysisData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      // this.attendanceData = [];
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  getAveragePercentageByStudent = async (data, advance?: boolean) => {
    this.loading = true;
    try {
      const res = await Axios.post('/api/Academic/GetAveragePercentageByStudent', data);
      if (advance) this.averagePercentageByStudentAdvance = res.data;
      else this.averagePercentageByStudent = res.data;
      console.log("averagePercentageByStudent", res.data);

      return Promise.resolve(res.data);
    } catch (err) {
      // this.attendanceData = [];
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  getMarkDistributionByHomeClass = async (data, advance?: boolean) => {
    this.loading = true;
    try {
      const res = await Axios.post('/api/Academic/GetMarkDistributionByHomeClass', data);
      if (advance) this.markDistributionByHomeClassAvance = res.data;
      else this.markDistributionByHomeClass = res.data;
      console.log('markDistributionByHomeClass', res.data);

      return Promise.resolve(res.data);
    } catch (err) {
      // this.attendanceData = [];
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  setAdvancedSelectedTeacherList = (list) => {
    this.advancedSelectedTeacherList = list;
  }

  getSchoolPATOverview = async () => {
    this.loadingExternal = true;
    try {
      const res = await Axios.get('/api/Academic/GetSchoolPATOverview');
      console.log('School PAT Overview', res.data);
      this.patOverviewData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loadingExternal = false;
    }
  }

  getSchoolPATDetail = async () => {
    this.loadingPATDetail = true;
    try {
      const res = await Axios.get('/api/Academic/GetSchoolPATDetail');
      console.log('School PAT Detail', res.data);
      this.patOverviewDetail = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      this.loadingPATDetail = false;
    }
  }

  getStudentPerformanceAndGrowthRateDistribution = async (data) => {
    this.loading = true;
    try {
      const res = await Axios.post('api/Academic/GetStudentPerformanceAndGrowthRateDistribution', data);
      console.log("Student Performance Growth Rate Distribution", res.data);
      this.sPerformanceGrowthData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      // this.attendanceData = [];
      return Promise.resolve(err.data);
    } finally {
      this.loading = false;
    }
  }

  getStudentSubmissionStatusChart = async (req: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`api/Academic/GetStudentSubmissionStatusChart`, req);
      console.log("Student Submission Status Chart", res.data);
      this.submissionStatusData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err);
    } finally {
      this.loading = false;
    }
  }
}