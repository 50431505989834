import { action, observable, makeObservable, toJS, runInAction } from 'mobx';
import { Axios } from '../utilities/network';

export default class FilterStore {

  loading: boolean = false;

  yearLevelList = [];

  yearLevelDropDownList = [];

  yearLevelCodeList = [];

  semesterList: any = [];

  semesterListForAdvance: any = [];

  semesterStudentShortList: any = [];

  orginalHomeRoomCodeList = [];

  homeRoomCodeList = [];

  homeRoomCodeListAdvance: any = [];

  filteredHomeRoomCodeList = [];

  filteredHomeRoomCodeListAdvance: any = [];

  domainList = [];

  // [07MAT, 08MAT, ...] (with id)
  subjectList = [];

  // [07MATA, 07MATB, ...] (with id)
  subjectClassList = [];

  classList = [];

  academicYearList = [];

  syncDataAcademicYearList: any = [];

  teacherList = [];

  currentYear: string = "";

  currentSemester: number = 0;

  selectedYear: string = "";

  selectedSemester: string = "";

  selectedHomeroom: string = "";

  selectedDomain: string = "";

  selectedSubject: string = "";

  selectedClass: string = "";

  attendanceTitleFirstLine: string = "";

  attendanceTitleSecondLine: string = "";

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      academicYearList: observable,
      syncDataAcademicYearList: observable,
      semesterList: observable,
      semesterListForAdvance: observable,
      semesterStudentShortList: observable,
      yearLevelList: observable,
      yearLevelDropDownList: observable,
      yearLevelCodeList: observable,
      homeRoomCodeList: observable,
      filteredHomeRoomCodeList: observable,
      domainList: observable,
      classList: observable,
      teacherList: observable,
      subjectClassList: observable,
      subjectList: observable,
      currentYear: observable,
      selectedYear: observable,
      selectedSemester: observable,
      selectedHomeroom: observable,
      selectedDomain: observable,
      selectedSubject: observable,
      selectedClass: observable,
      attendanceTitleFirstLine: observable,
      attendanceTitleSecondLine: observable,
      homeRoomCodeListAdvance: observable,
      getSubjectListByDomain: action,
      getSubjectClassListBySubjectAndSemester: action,
      screenSubjectListByYearLevel: action,
      currentSemester: observable,
      getSemesterDateList: action,
      getHomeRoomCodeList: action,
      getHomeRoomCodeByYearLevel: action,
      getDomainList: action,
      getTeacherList: action,
    });

    this.rootStore = rootStore;
  }

  getSemesterDateList = async () => {
    runInAction(() => {
      this.loading = true;
    })
    try {
      const res = await Axios.get(`api/Student/GetSemesterDateList`);
      console.log(res.data);
      this.academicYearList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  getSyncDataSemesterDateList = async () => {
    runInAction(() => {
      this.loading = true;
    })
    try {
      const res = await Axios.get(`api/Student/GetSyncDataSemesterDateList`);
      console.log(res.data);
      this.syncDataAcademicYearList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  getCurrentSemester = () => {
    if (this.academicYearList.length > 0) {
      let currentYear: any = this.academicYearList.filter((a) => a.yearDescription == new Date().getFullYear().toString())[0];
      console.log("Current semester year", toJS(currentYear));
      this.currentYear = currentYear.yearDescription;
      for (var semList of currentYear.academicSemesters) {
        var found = false;
        for (var academicTerm of semList.academicTerms) {
          for (var term of academicTerm.academicTermDates) {
            if ((new Date(term.startDate) <= new Date && new Date(term.endDate) > new Date()) ||
              (new Date(term.startDate) < new Date && new Date(term.endDate) >= new Date())
            ) {
              found = true;
              console.log("term.startDate", term.startDate + "  " + term.endDate);

              break;
            }
          }
          if (found) break;
        }

        if (found) {
          this.currentSemester = semList.id;
          break;
        }
      }
      if (this.currentSemester == 0) {
        if (new Date().getFullYear().toString() == this.currentYear) {
          if (currentYear.academicSemesters[0].academicTerms[0]) {
            let endDate: any = currentYear.academicSemesters[0].academicTerms[1].
              academicTermDates.find(t => t.yearLevelId != null);
            if (new Date() <= new Date(endDate)) this.currentSemester = currentYear.academicSemesters[0].id;
            else this.currentSemester = currentYear.academicSemesters[0].id;
          } 
          this.currentSemester = currentYear.academicSemesters[0].id;
        }
      }
      console.log("Current semester Id", toJS(this.currentSemester), toJS(this.academicYearList));

    }
  }

  getHomeRoomCodeList = async () => {
    runInAction(() => {
      this.loading = true;
    })

    try {
      const res = await Axios.get(`api/Student/GetHomeroomCodeList`);
      this.orginalHomeRoomCodeList = res.data;
      //Filter class code, remove duplicate classcode
      // var filteredData = [];
      // res.data.forEach(function(item){
      //   var i = filteredData.findIndex(x => x.yearLevelId == item.yearLevelId);
      //   if(i <= -1){
      //     filteredData.push({
      //       homeroomCode: item.homeroomCode, 
      //       yearLevelId: item.yearLevelId, 
      //       yearLevelCode: item.yearLevelCode
      //     });
      //   }
      // });
      // this.homeRoomCodeList = res.data.sort(
      //   (a, b) => a.yearLevelCode - b.yearLevelCode
      // );
      this.homeRoomCodeList = res.data.sort((a, b) => ('' + a.homeroomCode).localeCompare(b.homeroomCode));
      return Promise.resolve(res.data);
    } catch (err) {
      // this.behaviorData = [];
      return Promise.resolve(err.data);
    } finally {
      runInAction(() => {
        this.loading = false;
      })

    }
  }

  getDomainList = async () => {
    runInAction(() => {
      this.loading = true;
    })
    try {
      if (this.domainList && this.domainList.length > 1) {
        return;
      }
      const res = await Axios.get(`api/Student/GetDomainList`);
      // Sort the domain list
      runInAction(() => {
        this.domainList = res.data.sort(function (a, b) {
          if (a.domainDescription < b.domainDescription) { return -1; }
          if (a.domainDescription > b.domainDescription) { return 1; }
          return 0;
        });
      })

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.resolve(err.data);
    } finally {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  getSemesterBasedOnYear = (yearDescription: string, advance?: boolean) => {
    if (yearDescription == "") return;

    if (this.academicYearList.length > 0) {
      if (this.academicYearList.filter(
        ay => ay.yearDescription == yearDescription
      ).length > 0) {
        runInAction(() => {
          if (advance) this.semesterListForAdvance = this.academicYearList.filter(
            ay => ay.yearDescription == yearDescription
          )[0].academicSemesters;
          else {
            this.semesterList = this.academicYearList.filter(
              ay => ay.yearDescription == yearDescription
            )[0].academicSemesters;
          }
        })
      } else {
        runInAction(() => {
          if (advance) this.semesterListForAdvance = [];
          else this.semesterList = [];
        })
      }
    } else if (this.syncDataAcademicYearList.length > 0) {
      if (this.syncDataAcademicYearList.filter(
        ay => ay.yearDescription == yearDescription
      ).length > 0) {
        runInAction(() => {
          if (advance) this.semesterListForAdvance = this.syncDataAcademicYearList.filter(
            ay => ay.yearDescription == yearDescription
          )[0].academicSemesters;
          else {
            this.semesterList = this.syncDataAcademicYearList.filter(
              ay => ay.yearDescription == yearDescription
            )[0].academicSemesters;
          }
        })
      } else {
        runInAction(() => {
          if (advance) this.semesterListForAdvance = [];
          else this.semesterList = [];
        })
      }
    }
    console.log("sems list", toJS(this.semesterList), "for advacne", toJS(this.semesterListForAdvance));

  }

  getYearLevelBasedOnSemester = (semesterId: number) => {
    if (semesterId == 0) return;

    if (this.semesterList.length > 0) {
      this.yearLevelList = this.semesterList.filter(
        sem => sem.id == semesterId
      )[0].academicTermDates;
    }
  }

  getHomeRoomCodeByYearLevel = (yearLevelId: number) => {
    this.classList = [];
    //var yearLevelCode = this.yearLevelDropDownList.find(e => e.yearLevelId == yearLevelId).yearLevelCode;

    if (yearLevelId == 0) return;

    if (this.homeRoomCodeListAdvance.length > 0) {
      for (var hr of this.homeRoomCodeListAdvance) {
        if (hr.yearLevelId == yearLevelId && (hr.homeroomCode && (hr.homeroomCode.length == 2 || hr.homeroomCode != ""))
          //&& hr.homeroomCode[0] == yearLevelCode
        ) {
          this.classList.push(hr);
        }
        // var keepAction = false;
        // if(hr.yearLevelId == yearLevelId){
        //   keepAction = true;
        //   for(var c of this.classList){
        //     if(c.homeroomCode == hr.homeroomCode){
        //       keepAction = false;
        //       break;
        //     }
        //   } 
        // }
        // if(keepAction){
        //   console.log(toJS(hr));
        //   this.classList.push(hr);
        // }
      }
      this.classList = this.classList.sort((a, b) => ('' + a.homeroomCode).localeCompare(b.homeroomCode));
    }
  }




  getHomeRoomCodeListBySemesterId = (semesterIdList: any, advance?: boolean) => {

    var filteredData;
    if (semesterIdList.length == 1) {
      filteredData = this.orginalHomeRoomCodeList.filter(e =>
        e.semesterId == semesterIdList[0] && e.homeroomCode && e.homeroomCode != "NULL"
      )
    } else if (semesterIdList.length == 2) {
      filteredData = this.orginalHomeRoomCodeList.filter(e =>
        (e.semesterId == semesterIdList[0] || e.semesterId == semesterIdList[1]) && e.homeroomCode && e.homeroomCode != "NULL"
      )
    } else {
      filteredData = this.orginalHomeRoomCodeList.filter(e =>
        e.semesterId == semesterIdList && e.homeroomCode && e.homeroomCode != "NULL"
      )
    }

    if (advance) {
      this.homeRoomCodeListAdvance = filteredData.sort(
        (a, b) => parseFloat(a.yearLevelCode) - parseFloat(b.yearLevelCode)
      );

      runInAction(() => {
        this.filteredHomeRoomCodeListAdvance = filteredData.sort(
          (a, b) => parseFloat(a.yearLevelCode) - parseFloat(b.yearLevelCode)
        );
      })
    } else {
      this.homeRoomCodeList = filteredData.sort(
        (a, b) => parseFloat(a.yearLevelCode) - parseFloat(b.yearLevelCode)
      );

      runInAction(() => {
        this.filteredHomeRoomCodeList = filteredData.sort(
          (a, b) => parseFloat(a.yearLevelCode) - parseFloat(b.yearLevelCode)
        );
      })
    }

    var yearLevelIdList = [];
    this.yearLevelDropDownList = filteredData.map(element => {
      if (yearLevelIdList.indexOf(element.yearLevelId) < 0) {
        yearLevelIdList.push(element.yearLevelId);
        return {
          yearLevelId: element.yearLevelId,
          yearLevelCode: element.yearLevelCode
        }
      }
      return null
    }).filter(e => e != null);

    console.log("FILTER HOMEROOM CODE", toJS(semesterIdList), toJS(filteredData), toJS(filteredData.map(e => e.homeroomCode)));

    return filteredData.map(e => e.homeroomCode);
  }

  getTeacherList = async (take: number) => {
    try {
      const res = await Axios.get(`/api/Academic/GetTeacherList/${take}`);
      this.teacherList = res.data.sort((a, b) => ('' + a).localeCompare(b));
      console.log("Fetched teacher list: ", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      // this.attendanceData = [];
      return Promise.resolve(err.data);
    } finally {
    }
  }

  getSubjectListByDomain = (domainId: number) => {
    var filteredData = this.domainList.filter(e => e.id == domainId);
    if (filteredData.length < 1) {
      runInAction(() => {
        this.subjectList = [];
      })
    }
    else {
      runInAction(() => {
        var tempList = filteredData[0].systemDefinedSubjects.sort((a, b) => ('' + a.subjectCode).localeCompare(b.subjectCode));
        this.subjectList = tempList;
      })
    }
  }

  getSubjectClassListBySubjectAndSemester = (subjectId: number, semesterIdList: any) => {
    /*
      yearLevel ranges from 7 to 12
    */
    var filteredData = this.subjectList.filter(e => e.id == subjectId);
    if (filteredData.length < 1) {
      runInAction(() => {
        this.subjectClassList = [];
      })
    }
    else {
      var allClassList = filteredData[0].systemDefinedSubjectClasses;
      runInAction(() => {
        if (semesterIdList.length == 1)
          this.subjectClassList = allClassList.filter(e => e.fileSeq == semesterIdList[0]).sort((a, b) => ('' + a.classCode).localeCompare(b.classCode));
        else if (semesterIdList.length == 2)
          this.subjectClassList = allClassList.filter(e => e.fileSeq == semesterIdList[0] || e.fileSeq == semesterIdList[1]).sort((a, b) => ('' + a.classCode).localeCompare(b.classCode));
        console.log(toJS(this.subjectClassList));
      })
    }
  }

  screenSubjectListByYearLevel = (yearLevel: number) => {
    var filteredData = this.subjectList.filter(e => e.subjectDescription.includes('' + yearLevel));
    runInAction(() => {
      this.subjectList = filteredData;
    })
  }
}