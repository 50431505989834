import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import useStores from '../hooks/use-stores';
import { observer } from 'mobx-react';
import loginBg from '../images/login.jpg';
import { CircularProgress } from '@material-ui/core';
import { Link, navigate } from 'gatsby';
import { useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from '../../auth-config';
import ForgotPasswordPage from '../components/sign-in/forgot-password';
import ResetPasswordPage from '../components/sign-in/reset-password';
import { signInMethod } from '../constants/options';
import VmorSignInPage from '../components/sign-in/vmor-sign-in';
import { useTranslation } from 'react-i18next';


const LoginPageIntro = observer(({ callback }) => {
  const { userStore } = useStores();
  const { instance } = useMsal();
  const { t } = useTranslation();

  const signIn = () => {
    userStore.loading = true;
    localStorage.SIGN_IN_METHOD = signInMethod.sso;
    instance.loginRedirect(loginRequest).catch(e => console.log(e))
  }
  return (
    <div
      className="absolute top-1/2 left-1/2 rounded-xl"
      style={{ transform: "translate(-50%, -50%)" }}
    >
      <img
        className="w-full h-52 object-cover rounded-3xl rounded-b-none shadow-2xl transform 2xl:scale-150"
        src={loginBg}
        alt="logo"
      />

      <div className="p-6 bg-white rounded-b-3xl transform 2xl:scale-150">
        <div className="mb-2 text-3xl 2xl: text-barPrimary w-full text-center" style={{ color: '#cd595f' }}>
          {t('SIGN_IN_NOW')}
        </div>
        <div className="mb-4 text-gray-300 w-full text-center">
          {t('WELCOME_TO_THE_SYSTEM')}
        </div>
        
         <button
          className={`rounded-xl text-white w-full text-center my-4 ${userStore.loading ? 'bg-white py-2' : 'bg-barPrimary py-4'}`}
          type="button"
          id="sign-in-button"
          onClick={signIn}
          disabled={userStore.loading}
        >
          {
            userStore.loading ? <CircularProgress className="text-barPrimary" />
              :
              'SIGN IN (SSO)'
          }
        </button>
       {/* <button
          className={`rounded-xl text-white w-full text-center mb-4 ${userStore.loading ? 'bg-white py-2' : 'bg-barPrimary py-4'}`}
          type="button"
          id="sign-in-button"
          onClick={callback}
          disabled={userStore.loading}
        >
          {t('SIGN_IN')} (VMOR)
        </button> */}
      </div>
    </div>
  )
})

const LoginPage = observer(() => {
  const { t } = useTranslation();
  const [signInBySSO, setSignInBySSO] = useState<boolean>(true);
  const [page, setPage] = useState<string>("");
  const [tempToken, setTempToken] = useState<string>("");

  useEffect(() => {
    if (typeof window !== "undefined" && window.location.href.indexOf('?token=') > 0) {
      setSignInBySSO(false);
      setTempToken(window.location.href.split('?token=')[1]);
      setPage(t('RESET_PASSWORD'));
    }
  }, []);

  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 theme-primary bg-barPrimary z-10">
      <title>{t('SIGN_IN')} {page != "" ? '| ' + page : page}</title>
      {
        signInBySSO ? <LoginPageIntro callback={() => setSignInBySSO(false)} />
          : page === t('FORGOT_PASSWORD') ? <ForgotPasswordPage goLogin={() => setPage("")} />
            : page === t('RESET_PASSWORD') ? <ResetPasswordPage token={tempToken} />
              : <VmorSignInPage
                callback={() => setSignInBySSO(true)}
                goForgotPassword={() => setPage(t('FORGOT_PASSWORD'))}
              />
      }
    </div>
  )
})

export default LoginPage;

